import React, { useEffect, useState } from 'react'
import AuthRouter from './AuthRouter'
import AppRouter from './AppRouter'
import { getAuthUser } from '../../services/api/UserAuth'
import { BrowserRouter } from 'react-router-dom'
import { TranslationProvider } from '../../contexts/TranslationProvider'
// import '../../Home.css'

function Router() {
    const { userToken, user } = getAuthUser()
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    useEffect(() => {
        if (userToken && user) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
        const handleLogout = () => {
            setIsLoggedIn(false);
        };
        const handleLogin = () => {
            setIsLoggedIn(true);
        };
        window.addEventListener("logout", handleLogout);
        window.addEventListener("login", handleLogin);
        return () => {
            window.removeEventListener("logout", handleLogout);
            window.removeEventListener("login", handleLogin);
        };
    }, [userToken, user]);

    return (
        <TranslationProvider>
            <BrowserRouter>
                {isLoggedIn ? <AppRouter /> : <AuthRouter />}
            </BrowserRouter>
        </TranslationProvider>
    )
}

export default Router