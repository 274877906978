import { useEffect, useState, useContext, useRef } from 'react';
import React from 'react'
import { useTranslation } from '../../../contexts/TranslationProvider'
import EditContractTemplateContext from '../../../contexts/EditContractTemplateContext';
import { ClauseEntity, SubClauseEntity } from "../../../domain/entities";
import { SegmentedClauseParam } from '../../../domain/types/ClauseParams';

function EditParamBasicInfo({ param, clauseId, subClauseId }: { param: SegmentedClauseParam, clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'] }) {
  const { t, language } = useTranslation();
  const errorTranslationPath = t("pages.contractTemplateParam.addParamForm.errors");
  const translationPath = t("pages.contractTemplateParam.edition")
  const [errors, setErrors] = useState(null);
  const [paramName, setParamName] = useState(param?.name);
  const latestParamNameRef = useRef(paramName);
  const errorsRef = useRef(errors);
  const updateName = () => {
    console.log("Updating param name:", latestParamNameRef.current);
    onParamNameChanged(param, latestParamNameRef.current);
  }

  useEffect(() => {
    return () => {
      if (!errorsRef.current) {
        updateName();
      } else {
        console.log("Errors found, not updating name.", errorsRef.current);
      }
    };
  }, []);

  const {
    onParamNameChanged,
    onParamChanged,
    contractTemplate,
    combinedTemplateParams
  } = useContext(EditContractTemplateContext);

  const onchange = (value) => {
    setErrors(null);
    errorsRef.current = null;
    setParamName(value);
    latestParamNameRef.current = value;
    if (!value) {
      setErrors(t(errorTranslationPath.nameMissing));
      errorsRef.current = t(errorTranslationPath.nameMissing);
    }
    if (!/^[a-z_][a-z0-9_]*$/i.test(value)) {
      setErrors(t(errorTranslationPath.nameWrong));
      errorsRef.current = t(errorTranslationPath.nameWrong);
    }
    const existingParam = combinedTemplateParams.find(param => param.name === value);
    if (!existingParam) {
      return;
    }
    if (existingParam && (param.name !== value)) {
      setErrors(t(errorTranslationPath.nameExists));
      errorsRef.current = t(errorTranslationPath.nameExists);
    }
  }

  return (
    <div>
      {/* Edit param name */}
      <div className="edit-param-container">
        <div className="edit-param-name">
          <label className="label">{t(translationPath.parameterName)}</label>
          <input
            className="input"
            value={paramName}
            type="text"
            onChange={(e) => {
              onchange(e.target.value);
            }}
          />
        </div>
        <div className="error">
          {errors}
        </div>
      </div>
      {/* Edit parameter label */}
      <div className="edit-param-container">
        <div className="edit-param-name">
          <label className="label">{t(translationPath.parameterLabel)}</label>
          <input
            className="input"
            value={param?.label}
            type="text"
            onChange={(e) => {
              onParamChanged(clauseId, subClauseId, {
                ...param,
                label: e.target.value,
              })
            }}
            onBlur={(e) => {
              onParamChanged(clauseId, subClauseId, {
                ...param,
                label: e.target.value,
              })
            }}
          />
        </div>
      </div>

    </div>
  )
}

export default EditParamBasicInfo