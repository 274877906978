import { AxiosInstance } from "axios";
import { ReglementEntity } from "../../domain/entities/Reglement";
import { ModelClient } from "../ModelClient";

export class ReglementClient extends ModelClient<ReglementEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Reglement')
  }
  async addFile(reglementId: string, file: any): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.apiClient.post<{ rows: ReglementEntity[] }>(`${this.modelUrl}/addFile/${reglementId}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }

  async commit(reglementId: string): Promise<any> {
    const response = await this.apiClient.post<{ rows: ReglementEntity[] }>(`${this.modelUrl}/generateVectorstore/${reglementId}`);
    return response.data;
  }
}