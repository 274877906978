import React from 'react'
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";

function BeneficialRender({param, page, isEditing, value,valueName, onChange, onChangeName, errors}) {
  const { onParamChanged } = React.useContext(EditContractTemplateContext);
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  return (
    <div className={`input-param`}>
    <div className="input-type">{param.type}</div>
    <div>
      {!isEditing ? (
        <label style={{justifyContent: language === "ar" ? "right" : "left"}} className="custom-form-label">{translationPath.parameterName} : {param.name}</label>
      ) : (
        <>
        <textarea
          className="custom-form-textarea"
          value={valueName}
          onChange={onChangeName}
        />
        {
          errors.map((err, idx) => {
            if (err.where === "name") {
              return <div key={idx} className="error-message-dialog">{err.why}</div>
            }
          })
        }
        </>
      )}
    </div>
    <div>
      {!isEditing ? (
        <label style={{justifyContent: language === "ar" ? "right" : "left"}} className="custom-form-label">{translationPath.parameterLabel} :{param.label}</label>
      ) : (
        <textarea
          className="custom-form-textarea"
          value={value}
          onChange={onChange}
        />
      )}
    </div>
    <div className="checkbox-beneficial">
      <input
        type="checkbox"
        checked={
          param?.args?.beneficialTypes?.includes("Person") || false
        }
        name={"Person"}
        onChange={(e) => {
          const updatedParam = { ...param };
          if (!updatedParam.args) {
            updatedParam.args = { beneficialTypes: [] };
          }
          updatedParam.args.beneficialTypes = e.target.checked
            ? ([...new Set(["Person"])].sort() as any)
            : updatedParam.args.beneficialTypes?.filter(
                (i) => i !== "Person"
              );
          onParamChanged(page.id, updatedParam);
        }}
        disabled={false}
      />
      <label htmlFor="Person">
        {t(translationPath.beneficial.person)}{" "}
      </label>
    </div>
    <div className="checkbox-beneficial">
      <input
        type="checkbox"
        name={"Company"}
        checked={
          param?.args?.beneficialTypes?.includes("Company") || false
        }
        onChange={(e) => {
          const updatedParam = { ...param };
          if (!updatedParam.args) {
            updatedParam.args = { beneficialTypes: [] };
          }
          updatedParam.args.beneficialTypes = e.target.checked
            ? ([...new Set(["Company"])].sort() as any)
            : updatedParam.args.beneficialTypes?.filter(
                (i) => i !== "Company"
              );
          onParamChanged(page.id, updatedParam);
        }}
        disabled={false}
      />
      <label htmlFor="Company">
        {" "}
        {t(translationPath.beneficial.company)}
      </label>
    </div>
    <div className="checkbox-beneficial">
      <input
        type="checkbox"
        checked={
          param?.args?.beneficialTypes?.includes("Minor") || false
        }
        name={"Minor"}
        onChange={(e) => {
          const updatedParam = { ...param };
          if (!updatedParam.args) {
            updatedParam.args = { beneficialTypes: [] };
          }
          updatedParam.args.beneficialTypes = e.target.checked
            ? ([...new Set(["Minor"])].sort() as any)
            : updatedParam.args.beneficialTypes?.filter(
                (i) => i !== "Minor"
              );
          onParamChanged(page.id, updatedParam);
        }}
        disabled={false}
      />
      <label htmlFor="Minor">
        {t(translationPath.beneficial.minor)}{" "}
      </label>
    </div>
  </div>
  )
}

export default BeneficialRender