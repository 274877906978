import React from 'react'
import { useTranslation } from "../../../../contexts/TranslationProvider";

function DefaultRender({param, value, valueName,isEditing, onChange, onChangeName, errors}) {
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");

  return (
    <div className={`input-param`}>
    <div className="input-type">{param.type}</div>
    <div>
      {!isEditing ? (
        <label style={{justifyContent: language === "ar" ? "right" : "left"}} className="custom-form-label">{translationPath.parameterName} : {param.name}</label>
      ) : (
        <>
        <textarea
          className="custom-form-textarea"
          value={valueName}
          onChange={onChangeName}
        />
        {
          errors.map((err, idx) => {
            if (err.where === "name") {
              return <div key={idx} className="error-message-dialog">{err.why}</div>
            }
          })
        }
        </>
      )}
    </div>
    <div>
      {isEditing ? (
        <textarea
          className="custom-form-textarea"
          value={value}
          onChange={onChange}
        />
      ) : (
        <div style={{justifyContent: language === "ar" ? "right" : "left"}} className="custom-form-label"> {translationPath.parameterLabel} :{param.label}</div>
      )}
    </div>
  </div>
  )
}

export default DefaultRender