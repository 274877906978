import ReactDOMServer from 'react-dom/server';
import { AxiosInstance } from 'axios';
const END_POINT = "/utils/htmlToDocx";

// export const componentToDocx = async (apiClient: AxiosInstance, component: JSX.Element) => {
export const htmlToDocx = async (apiClient: AxiosInstance, html: string, fileName?: string) => {
  try {
    const response = await apiClient.post<{ url: string }>(`${END_POINT}?fileName=${fileName}`, html, {
      headers: {
        "Content-Type": "text/plain"
      }
    });
    const docxUrl = response.data.url
    return docxUrl;
  } catch (error) {
    console.error(
      `Error fetching docx file`,
      error
    );
    return null;
  }
};
export const componentToHtml = (component: React.JSX.Element) => {
  const htmlString = ReactDOMServer.renderToString(component);
  return htmlString
}
export const componentToDocx = async (apiClient: AxiosInstance, component: React.JSX.Element) => {
  const htmlString = ReactDOMServer.renderToString(component);
  return await htmlToDocx(apiClient, htmlString)
};

