import "./contractTemplateClauseInput.scss";
import { useContext, useEffect, useState } from "react";
import SelectionContext from "../../../contexts/SelectionContext";
import { getClauseName } from "../../../helpers/ParseTemplateData";
import { FieldValues, UseFormReturn } from "react-hook-form";
import React from "react";
import ContractTempleteParam from "./ContractTempleteParam";
import { useCollapse } from "react-collapsed";
import { isValidInputValue } from "../../../helpers/CompletionPercentage";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
import { ClauseEntity } from "../../../domain/entities";
import { removeDuplicates } from "../../../utils/array";
import SubClauseHeader from "./SubClauseHeader";
const { default: EditIcon } = require("../../../assets/edit_icon.svg");
const { default: EditIconOn } = require("../../../assets/edit_icon_on.svg");

function ContractTemplateClauseInput({
  clause,
  clauseIndex,
  handleClick,
  inputGroupRef,
  form
}: {
  clause: ClauseEntity;
  clauseIndex?: number;
  handleClick: (clauseId: any) => void;
  inputGroupRef: React.MutableRefObject<any>;
  form: UseFormReturn<FieldValues, any>;
}) {
  const {
    previewValues,
    setPreviewValues,
    onParamReorder,
    onClauseNameChange
  } = useContext(EditContractTemplateContext);
  function handleInputChange(
    e,
    paramName,
    clauseId,
    subClauseId,
  ) {
    let value = e.target.value;
    if (paramName && isValidInputValue(value)) {
      setSelected({
        eventType: 'ParamValueChange',
        clauseId: clauseId,
        paramName
      })
    }

    setPreviewValues((prevState) => ({
      ...prevState,
      [paramName]: value,
    }));
  }


  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = form;
  const clauseId = clause.id
  const { selected, setSelected } = useContext(SelectionContext);
  const isSelected = selected.clauseId === clauseId;
  const [isExpanded, setExpanded] = useState(isSelected);
  const [editName, setEditName] = useState(false);
  const [clauseName, setClauseName] = useState(clause.name);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  useEffect(() => {
    setExpanded(isSelected)
  }, [isSelected]);

  function getCurrentValue(param, inputValues) {
    let currentValue;
    currentValue = inputValues?.[param.name] ?? "";
    return currentValue;
  }

  const handleClauseChange = () => {
    if (clauseName !== clause.name) {
      onClauseNameChange(clauseId, null, clauseName);
    }
    setEditName(false);
  }

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.droppableId !== result.source.droppableId) return;
    const elementInfo = result.draggableId.split("-");
    if (elementInfo.length < 3) {
      const clauseId = elementInfo[0];
      const destinationIdx = result.destination.index;
      const sourceIdx = result.source.index;
      onParamReorder(parseInt(clauseId), null, parseInt(sourceIdx), parseInt(destinationIdx));
    } else {
      const clauseId = elementInfo[0];
      const subClauseId = elementInfo[1];
      const destinationIdx = result.destination.index;
      const sourceIdx = result.source.index;
      onParamReorder(parseInt(clauseId), parseInt(subClauseId), parseInt(sourceIdx), parseInt(destinationIdx))
    }
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        key={String(clauseId)}
        id="clause-group"
        className={`clause-input-${clauseId} edit-contract-clause-input px-4 py-2 mx-1 my-2 ${isSelected ? "selected-input-container" : ""}`}
        onClick={() => handleClick(clauseId)}
        ref={isSelected ? inputGroupRef : null}

      >
        <div className="d-flex gap-2" {...getToggleProps()}>
          <div className="edit-contract-clause-input-header">
            {isSelected ?
              (!editName ? (<b style={{ paddingTop: "3px" }}>{getClauseName(clause.name, clauseIndex)}</b>)
                :
                (
                  <input
                    type="text"
                    value={clauseName}
                    style={{ height: "25px" }}
                    onChange={(e) => setClauseName(e.target.value)}
                    onBlur={handleClauseChange}
                  />
                )) : (<b>{getClauseName(clause.name, clauseIndex)}</b>)
            }
            {isSelected && <img src={editName ? EditIconOn : EditIcon} alt="edit" onClick={() => setEditName(!editName)} />}
          </div>
          {isSelected && (
            <React.Fragment key={`${clauseId}_toggle`}>
              <div style={{ paddingTop: "5px" }} className="switch">
                <input
                  type="checkbox"
                  id={`toggle_${clauseId}`}
                // defaultChecked={!isClauseExcluded(clauseId, excludedClauses)}
                // onChange={(event) => handleExclusionToggle(event, clauseId, false)}
                />
                <label
                  htmlFor={`toggle_${clauseId}`}
                ></label>
              </div>
            </React.Fragment>
          )}
        </div>
        <div {...getCollapseProps()}>
          <Droppable droppableId={clause.id + "-"}>
            {(provided) => (
              <div
                key={`${clauseId}`}
                onClick={() => handleClick(clauseId)}
                className="template-param-list"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {removeDuplicates(clause.segmentation.segmentedParams, 'name').map((param, paramIdx) => {
                  const currentValue = getCurrentValue(param, previewValues);
                  return (
                    <Draggable
                      draggableId={clause.id + "-" + paramIdx}
                      index={paramIdx}
                      key={`${paramIdx}-${clauseId}`}
                    >
                      {(provided) => (
                        <div
                          className="template-param-element"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ContractTempleteParam
                            param={param}
                            currentValue={currentValue}
                            clauseId={clauseId}
                            subClauseId={null}
                            handleInputChange={handleInputChange}
                            register={register}
                            control={control}
                          />
                        </div>)}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>)}
          </Droppable>
          <div className="subclause-param-list">
            {clause.subClauses!.map((subClause, subClauseIndex) => {
              return (
                <div
                  key={`${clause.id}_${subClause.id}`}
                  onClick={() => handleClick(clause.id)}
                >
                  <SubClauseHeader subClauseName={subClause.name} subClauseIndex={subClauseIndex} clauseIndex={clauseIndex} clauseId={clause.id} subClauseId={subClause.id} />
                  <Droppable droppableId={clause.id + "-" + subClause.id}>
                    {(provided) => (
                      <div
                        className="subclause-param-list"
                        ref={provided.innerRef}
                        key={`${clause.id}_${subClause.id}`}
                        {...provided.droppableProps}
                      >
                        {subClause.segmentation.segmentedParams
                          .filter(subClaueParam => !clause.segmentation.segmentedParams.find(clauseParam => clauseParam.name == subClaueParam.name))
                          .map((param, paramIdx) => {
                            const currentValue = getCurrentValue(param, previewValues);
                            return (
                              <Draggable
                                draggableId={clause.id + "-" + subClause.id + "-" + param.name}
                                index={paramIdx}
                                key={`${param.name}-${clause.id}-${subClause.id}`}
                              >
                                {(provided) => (
                                  <div
                                    className="template-param-element"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{ ...provided.draggableProps.style }}
                                  >
                                    <ContractTempleteParam
                                      param={param}
                                      clauseId={clause.id}
                                      subClauseId={subClause.id}
                                      currentValue={currentValue}
                                      handleInputChange={handleInputChange}
                                      register={register}
                                      control={control}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </DragDropContext>
  );
}
export default ContractTemplateClauseInput;
