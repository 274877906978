import "./commonmodal.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import CustomCombobox from "../../../components/common/CustomCombobox";
import { useNavigate } from "react-router-dom";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import Loading from "../../../components/common/Loading";
import { ProjectEntity, ProjectTemplateRequirementEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../../../domain/entities";
import { useTranslation } from '../../../contexts/TranslationProvider';
import EditFormContext, { EditFormContextType } from "../../../contexts/EditFormContext";
import EventManager from "../../../services/EventManager";
import { TypeLevel1Client } from "../../../services/api/TypeLevel1Client";
import { FormClient } from "../../../services/api/FormClient";
import { getName } from "../../../helpers/Translation";
import FileInputAdvanced from "../../../components/common/FileInputAdvanced";
import { FormTemplateClient } from "../../../services/api/FormTemplateClient";
import { ProjectClient } from "../../../services/api/ProjectClient";
import { toast } from "react-toastify";
import { set } from "react-hook-form";
import PendingAi from "../../../components/common/newDesign/PendingAi";
import { numberToEnumList } from "../../../domain/Project";
import { ProjectTemplateRequirementType } from "../../../domain/Project";

interface CreateFormModalType {
  onClose: () => void;
  dataProject?: { requirement: ProjectTemplateRequirementEntity, project: ProjectEntity };
  typeReq?: number
}
function CreateFormModal({ onClose, dataProject, typeReq }: CreateFormModalType) {
  const { t, language } = useTranslation();
  const [errors, setErrors] = useState<{
    formName: boolean
    level1: boolean;
    level2: boolean;
    level3: boolean;
  }>({
    formName: false,
    level1: false,
    level2: false,
    level3: false,
  });
  const navigate = useNavigate();
  const [fileError, setFileError] = useState(false);
  const [importFromDoc, setImportFromDoc] = useState(false);
  const [editFormContext, setEditFormContext] = useState<EditFormContextType>(null)
  useEffect(() => {
    EventManager.subscribe('EditFormContext', setEditFormContext)
    return () => {
      EventManager.unsubscribe('EditFormContext', setEditFormContext)
    }
  }, [])
  const [requestLoading, setRequestLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formName, setFormName] = useState("");
  type CreateFormFromDocRequest = {
    file: Blob & { name: string };
    name: string;
    level1Id?: TypeLevel1Entity['id'];
    level2Id?: TypeLevel2Entity['id'];
    level3Id?: TypeLevel3Entity['id'];
  }
  const [req, setReq] = useState<CreateFormFromDocRequest>({
    name: "",
    file: undefined
  });
  const [levelsFilter, setLevelsFilter] = useState<{
    level1Id: TypeLevel1Entity['id'];
    level2Id: TypeLevel2Entity['id'];
    level3Id: TypeLevel3Entity['id'];
  }>({
    level1Id: null,
    level2Id: null,
    level3Id: null,
  });
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const [levels1, setLevels1] = useState<TypeLevel1Entity[]>([]);
  const [formExistsError, setFormExistsError] = useState(false);
  const [checkFormExistsTimeout, setCheckFormExistsTimeout] = useState<NodeJS.Timeout>(null);
  const modalContentRef = useRef(null);
  const apiClient = useApiClientWithLoading();
  const formClient = new FormClient(apiClient)
  const typeLevel1Client = new TypeLevel1Client(apiClient)
  const formTemplateClient = new FormTemplateClient(apiClient)
  const projectClient = new ProjectClient(apiClient)

  useEffect(() => {
    setLoading(true);
    (async () => {
      const { rows } = await typeLevel1Client.getAllWithFormTemplate(language);
      setLevels1(rows)
      setLoading(false);
    })();
  }, []);
  const selectedLevel1 = levelsFilter.level1Id && levels1.find(l => l.id == levelsFilter.level1Id)
  const levels2 = selectedLevel1 && selectedLevel1.levels2
  const selectedLevel2 = levelsFilter.level2Id && levels2?.find(l => l.id == levelsFilter.level2Id)
  const levels3 = selectedLevel2 && selectedLevel2.levels3
  const selectedLevel3 = levelsFilter.level3Id && levels3?.find(l => l.id == levelsFilter.level3Id)
  useEffect(() => {
    if (dataProject) {
      setLevelsFilter({
        level1Id: dataProject?.requirement?.level1Id,
        level2Id: dataProject?.requirement?.level2Id,
        level3Id: dataProject?.requirement?.level3Id
      })
    }
  }, [dataProject]);
  const checkFormFields = (formName, selectedLevel1, selectedLevel2, selectedLevel3) => {
    let hasError = false;
    console.log(levelsFilter)
    console.log(levels3, levels2, levels1)
    // Check formName
    if (formName === "") {
      setErrors(prevErrors => ({
        ...prevErrors,
        formName: true,
      }));
      hasError = true;
    }
    // Check type levels
    if (!selectedLevel1) {
      setErrors(prevErrors => ({
        ...prevErrors,
        level1: true,
      }));
      hasError = true;
    }
    if (!selectedLevel2 && levels2 && levels2.length > 0) {
      setErrors(prevErrors => ({
        ...prevErrors,
        level2: true,
      }));
      hasError = true;
    }
    if (!selectedLevel3 && levels3 && levels3.length > 0) {
      setErrors(prevErrors => ({
        ...prevErrors,
        level3: true,
      }));
      hasError = true;
    }

    return !hasError;
  };
  const onSubmit = async (e) => {
    const { project, requirement } = dataProject ?? {}

    e.preventDefault()
    if (!importFromDoc) {
      const result = checkFormFields(formName, selectedLevel1, selectedLevel2, selectedLevel3)
      if (result === true) {
        const { row: formTemplate } = await formTemplateClient.select(levelsFilter.level1Id, levelsFilter.level2Id, levelsFilter.level3Id, language, 'full');
        if (!formTemplate) {
          setLoading(false);
          return toast("Form Template not found")
        }
        const form = await formClient.create({
          name: formName,
          annexes: [],
          isAIGenerated: false,
          paramValues: {},
          templateId: formTemplate.id,
        })
        await projectClient.update(dataProject.project.id, {
          values: {
            ...dataProject.project?.values,
            [dataProject.requirement.id]: {
              type: ProjectTemplateRequirementType.FORM,
              id: form.id,
            },
          },
        });
        navigate(`/edit-form/${form.id}`, {
          state: {
            project,
            requirement,
            requirementType: typeReq && typeReq,
            previousPathname: [
              { link: '/projets', label: t('sidebar.myProjects') },
              { link: `/projet/${project?.id}`, label: project?.name }
            ]
          }
        });
      }
    } else {

      const result = checkFormFields(formName, selectedLevel1, selectedLevel2, selectedLevel3)
      if (!req.file) {
        setFileError(true)
      }
      console.log(result)
      if (result === true && req.file) {
        const translationPath = "modals.importForm."

        setRequestLoading(true)
        try {
          console.log(selectedLevel1.id, selectedLevel2?.id, selectedLevel3?.id)
          const { row: template } = await formTemplateClient.select(selectedLevel1.id, selectedLevel2?.id, selectedLevel3?.id, language, 'pure');
          if (!template) throw new Error("Error selecting template")
          const { row: newForm } = await formClient.generateFormFromDocAndTemplate({ file: req.file, name: formName, templateId: template.id })
          if (!newForm || !(newForm as any).id) throw new Error("Error generating form")
          const project = dataProject?.project
          const requirement = dataProject?.requirement
          const result = await projectClient.update(project?.id, {
            values: {
              ...project?.values,
              [requirement.id]: {
                type: ProjectTemplateRequirementType.FORM,
                id: newForm.id,
              },
            },
          });
          if (!result) throw new Error("Error updating project")
          toast.success(t(translationPath + "messages.success"));
          console.log(newForm.id)
          return navigate(`/edit-form/${newForm.id}`, {
            state: {
              project: dataProject && dataProject?.project,
              requirementType: typeReq && typeReq,
              requirement: dataProject && dataProject?.requirement,
              previousPathname: [
                { link: '/projets', label: t('sidebar.myProjects') },
                { link: `/projet/${project?.id}`, label: project?.name }
              ]
            }
          })
        } catch (error) {
          setRequestLoading(false)
          toast.error(t(translationPath + "messages.error"));
        } finally {
          setRequestLoading(false)
          onClose()
        }
      }
    }
  };

  useEffect(() => {
    if (checkFormExistsTimeout) {
      clearTimeout(checkFormExistsTimeout)
    }
    setCheckFormExistsTimeout(setTimeout(async () => {
      try {
        if (formName !== "") {
          const count = await formClient.countAllByFilter({ "name": formName });
          setFormExistsError(count >= 1);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setCheckFormExistsTimeout(null)
      }
    }, 500))
  }, [formName]);

  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("pages.pilot.popups.form.title")}</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              <div className="modal-body modal-body-pilotage" ref={modalContentRef}>
                {
                  requestLoading ? <PendingAi height="100%" /> :
                    <>
                      <div className="modal-body-multi-choice">
                        <div className={`modal-body-choice-${!importFromDoc ? "active" : "inactive"}`} onClick={() => setImportFromDoc(false)}>
                          {t("pages.pilot.popups.form.title")}
                        </div>
                        <div className={`modal-body-choice-${importFromDoc ? "active" : "inactive"}`} onClick={() => setImportFromDoc(true)}>
                          {t("modals.importForm." + "title")}
                        </div>
                      </div>
                      <p>{t(importFromDoc ? "pages.listener.cards.form.description" : "pages.pilot.popups.form.description")}</p>
                      {loading ? (
                        <Loading height="35vh" />
                      ) : (
                        <form action="" className="form-group" onSubmit={onSubmit}
                          style={{ maxHeight: "500px", marginBottom: "20%" }}
                        >
                          <p className="fw-bolder">{t("pages.pilot.popups.form.required")}</p>
                          <div className="form-input-content">
                            <div className="w-50">
                              <label htmlFor="formName" className="case-form-labelName">{t("pages.pilot.popups.form.inputs.formName.name")}</label>
                              <div className="form-floating mb-3 case-form-labelInput">
                                <input
                                  type="text"
                                  className="form-control custom-color-input input-popup"
                                  id="formName"
                                  placeholder={t("pages.pilot.popups.form.inputs.formName.placeholder")}
                                  onChange={(event) => {
                                    const value = event.target.value;
                                    setFormName(value);
                                    setErrors(prevErrors => ({
                                      ...prevErrors,
                                      formName: false,
                                    }));
                                  }}
                                />
                              </div>
                              {errors.formName && <p className="text-danger py-0 my-0 py-0 my-0">{t("pages.pilot.popups.form.inputs.formName.error1")}</p>}
                              {formExistsError && <p className="text-danger py-0 my-0 py-0 my-0">{t("pages.pilot.popups.form.inputs.formName.error2")}</p>}
                            </div>
                            {dataProject ? (
                              <div className="content-levels">
                                <>
                                  <label className="case-form-labelName">{t("pages.pilot.popups.form.inputs.type1.name")}</label>
                                  <select value={getName(dataProject?.requirement?.level1, language)} disabled>
                                    <option>{getName(dataProject?.requirement?.level1, language)}</option>
                                  </select>
                                </>
                                {dataProject?.requirement?.level2 && (
                                  <>
                                    <label className="case-form-labelName">{t("pages.pilot.popups.form.inputs.type2.name")}</label>
                                    <select value={getName(dataProject?.requirement?.level2, language)} disabled>
                                      <option>{getName(dataProject?.requirement?.level2, language)}</option>
                                    </select>
                                  </>
                                )}
                                {dataProject?.requirement?.level3 && (
                                  <>
                                    <label className="case-form-labelName">{t("pages.pilot.popups.form.inputs.type3.name")}</label>
                                    <select value={getName(dataProject?.requirement?.level3, language)} disabled>
                                      <option>{getName(dataProject?.requirement?.level3, language)}</option>
                                    </select>
                                  </>
                                )}
                              </div>
                            ) : (
                              <div style={{ width: "40%" }}>
                                <CustomCombobox
                                  fullWidth
                                  label={t("pages.pilot.popups.form.inputs.type1.name")}
                                  options={levels1}
                                  value={selectedLevel1}
                                  optionDisplay={(option: TypeLevel1Entity) => getName(option, language)}
                                  optionValue={(option: TypeLevel1Entity) => option?.id}
                                  onChange={(level1Id: TypeLevel1Entity['id']) => {
                                    setLevelsFilter({
                                      level1Id,
                                      level2Id: null,
                                      level3Id: null
                                    });
                                    setErrors(prevErrors => ({
                                      ...prevErrors,
                                      level1: false,
                                    }));
                                  }}
                                  isOpen={openCombobox === "level1"}
                                  setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level1" : null)}
                                />
                                {errors.level1 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.form.inputs.type1.error")}</p>}
                                {levels2?.length > 0 && (
                                  <CustomCombobox
                                    fullWidth
                                    label={t("pages.pilot.popups.form.inputs.type2.name")}
                                    options={levels2}
                                    value={selectedLevel2}
                                    optionDisplay={(option: TypeLevel2Entity) => getName(option, language)}
                                    optionValue={(option: TypeLevel2Entity) => option?.id}
                                    onChange={(level2Id: TypeLevel2Entity['id']) => {
                                      setLevelsFilter({
                                        ...levelsFilter,
                                        level2Id,
                                        level3Id: null
                                      });
                                      setErrors(prevErrors => ({
                                        ...prevErrors,
                                        level2: false,
                                      }));
                                    }}
                                    isOpen={openCombobox === "level2"}
                                    setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level2" : null)}
                                  />
                                )}
                                {errors.level2 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.form.inputs.type2.error")}</p>}
                                {levels3?.length > 0 && (
                                  <CustomCombobox
                                    fullWidth
                                    label={t("pages.pilot.popups.form.inputs.type3.name")}
                                    options={levels3}
                                    value={selectedLevel3}
                                    optionDisplay={(option: TypeLevel3Entity) => getName(option, language)}
                                    optionValue={(option: TypeLevel3Entity) => option?.id}
                                    onChange={(level3Id: TypeLevel3Entity['id']) => {
                                      setLevelsFilter({
                                        ...levelsFilter,
                                        level3Id,
                                      });
                                      setErrors(prevErrors => ({
                                        ...prevErrors,
                                        level3: false,
                                      }));
                                    }}
                                    isOpen={openCombobox === "level3"}
                                    setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level3" : null)}
                                  />
                                )}
                                {errors.level3 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.form.inputs.type3.error")}</p>}
                              </div>)}
                          </div>
                          {importFromDoc && <><FileInputAdvanced
                            value={req.file}
                            label={t("pages.listener.popups.contract.inputs.file.placeholder")}
                            onChange={(file) => {
                              setFileError(false)
                              setReq({
                                ...req, file
                              })
                            }}
                            className="mt-3"
                          />
                            {fileError && <p className="text-danger py-0 my-0 py-0 my-0" style={{ paddingTop: "10px" }}>{t("pages.pilot.popups.form.inputs.fileError")}</p>}
                          </>}
                          <div className="modal-footer" style={{ marginTop: "100px" }}>
                            <button
                              disabled={formExistsError || !!checkFormExistsTimeout}
                              type="submit" className="btn btn-primary btn-import" style={{ left: "4.5%" }}>
                              {t("pages.pilot.popups.form.button")}
                            </button>
                          </div>
                        </form>
                      )}
                    </>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateFormModal;
