import { AxiosInstance } from "axios";
import { ContractEntity, ContractTemplateEntity, FormEntity, GroupEntity, UserEntity, ValidateReq } from "../../domain/entities";
import { ModelClient } from "../ModelClient";
import { CreateFormFromDocRequest } from "../../domain/Form";

export class FormClient extends ModelClient<FormEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Form')
  }

  async generateFormFromDocAndTemplate(req: CreateFormFromDocRequest) {
    const url = new URL(this.modelUrl + "/generateWithAIAndFormTemplate")

    const formData = new FormData();
    formData.append("file", req.file);
    req.name && formData.append("name", req.name);
    url.searchParams.append("templateId", String(req.templateId))
    const response = await this.apiClient.post<{ row: FormEntity }>(url.href, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }
  async migrateToContract(id: FormEntity['id'], name: ContractEntity['name'], contractTemplateId: ContractTemplateEntity['id']) {
    const url = new URL(this.modelUrl + "/migrateToContract")
    const response = await this.apiClient.post<{ row: ContractEntity }>(url.href, {}, {
      params: { id, name, contractTemplateId },
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  }
  async validate(id: FormEntity['id'], validationData: ValidateReq) {
    const response = await this.apiClient.post<{ count: number, rows: FormEntity[] }>(`${this.modelUrl}/${id}/validate`, validationData);
    return response.data;
  }

  async getAssignedGroupForms(schema: string = 'nested') {
    const reponse = await this.apiClient.get<{ count: number, rows: FormEntity[], groups: GroupEntity[] }>(`${this.modelUrl}/assignedGroupForms?schema=${schema}`);
    return reponse.data;
  }
}