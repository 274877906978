import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./renderSegmentedText.scss";
import {
  ClauseEntity,
  ContractEntity,
  DocumentEntity,
  SubClauseEntity,
} from "../../domain/entities";
import {
  findParamBoundaries,
  getRenderSegments,
} from "../../helpers/segmentation";
import {
  RenderSegments,
  SegmentedText,
  SegmentedTextType,
} from "../../domain/types/ClauseParams";
import { StateSync } from "../../utils/state";
import SelectionContext from "../../contexts/SelectionContext";
import TableRender from "./TableRender";
import { useTranslation } from "../../contexts/TranslationProvider";
import FilePreviewModal from "../modals/FilePreviewModal";
import { useApiClientWithLoading } from "../../services/api/ApiClient";
import { DocumentClient } from "../../services/api/DocumentClient";
import { fetchTextFileUrl } from "../../helpers/helper";

interface RenderSegmentedTextProps {
  inputValues: Record<string, any>;
  clauseId: ClauseEntity["id"];
  segmentation: ClauseEntity["segmentation"];
  subClauseId?: SubClauseEntity["id"];
  fileNames: ContractEntity["fileNames"];
  beneficialsMap: ContractEntity["beneficialsMap"];
  segmentsOverrides: Record<string, string>;
  onSegmentChange: (id: string, text: string) => void;
  isSelected: boolean;
  isEditing: boolean;
  formattedRawRef?: React.MutableRefObject<any>;
}
function RenderSegmentedText({
  segmentation,
  fileNames,
  beneficialsMap,
  segmentsOverrides,
  onSegmentChange,
  isSelected,
  isEditing,
  inputValues,
}: RenderSegmentedTextProps) {
  const { selected } = useContext(SelectionContext);

  const { t, language } = useTranslation();

  const [editingSegmentId, setEditingSegmentId] = useState(null);
  const [changedParamBoundaries, setChangedParamBoundaries] = useState<
    [number, number][]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToPreview, setFileToPreview] = useState<DocumentEntity | null>(null);
  const apiClient = useApiClientWithLoading();
  const documentClient = new DocumentClient(apiClient);
  const [text, setText] = useState<string>(null);
  const spanRef = useRef(null);
  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.contentEditable = true;
      spanRef.current.focus();
    }
  }, [editingSegmentId]);

  const stateSync = useMemo(
    () =>
      new StateSync(
        setEditingSegmentId,
        (prev, newValue) => {
          return prev || newValue;
        },
        100
      ),
    [setEditingSegmentId]
  );

  const renderSegments: RenderSegments = getRenderSegments(
    segmentation.segmentedText,
    inputValues,
    fileNames,
    beneficialsMap,
    segmentation.segmentedParams,
    t,
    language
  ).map((seg) => {
    return {
      ...seg,
      value: seg.value?.replaceAll(/\r\n/g, "\n"),
    };
  });
  const handlePreviewFile = async (id) => {
    if (id && Number(id)) {
      try {
        const row = await documentClient.getById(id);
        const text = await fetchTextFileUrl(row?.textFile);
        if (row && text) {
          setFileToPreview(row);
          setText(text);
          setIsModalOpen(true);
        }
      } catch (err) {
        if (err.response.status === 400) {
          setText("");
          setIsModalOpen(false)
        }
      }
    }
  };
  const handleStaticContentChange = (id: SegmentedText[number][0]) => (e) => {
    const newContent = e.target.innerText;
    console.log(JSON.stringify(newContent));

    const segment = renderSegments.find((seg) => seg.id == id);
    if (segment) {
      let newValue;
      if (newContent == segment.value) {
        newValue = segmentsOverrides;
        delete newValue[id];
      } else {
        newValue = {
          ...segmentsOverrides,
          [id]: newContent,
        };
      }
      stateSync.set(null);
      onSegmentChange(id, newContent);
    } else {
      console.warn(id);
    }
  };
  useEffect(() => {
    const paramNames = renderSegments
      .map((seg) => (seg as any).paramName)
      .filter((seg) => seg);
    if (
      selected.eventType == "ParamValueChange" &&
      selected.paramName &&
      paramNames.includes(selected.paramName)
    ) {
      const boundaries = findParamBoundaries(
        renderSegments,
        selected.paramName
      );
      setChangedParamBoundaries(boundaries);
    } else {
      setChangedParamBoundaries([]);
    }
  }, [selected]);
  const openFilePreview = async (id) => {
    await handlePreviewFile(id);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFileToPreview(null);
  };

  return (
    <div style={{ marginTop: "30px" }}>
      {renderSegments.map((segment, idx) => {
        const isHighlighted = changedParamBoundaries.some(
          (b) => b[0] < idx && idx < b[1]
        );
        const { id, value, type } = segment;
        const key = idx;
        const text = segmentsOverrides[id] ?? value;

        const breakedHTMLText = text?.replaceAll(/\n/g, "<br/>");
        const editableProps: React.DetailedHTMLProps<
          React.HTMLAttributes<HTMLElement>,
          HTMLElement
        > = (isEditing && {
          contentEditable: true,
          suppressContentEditableWarning: true,
          onClick: (e) => stateSync.set(id),
        }) ||
          {};
        const editingProps: React.DetailedHTMLProps<
          React.HTMLAttributes<any>,
          any
        > = (isEditing && {
          ref: spanRef,
          contentEditable: true,
          suppressContentEditableWarning: true,
          onBlur: handleStaticContentChange(id),
        }) ||
          {};
        const isSegmentEditing = isEditing && editingSegmentId == id;
        switch (type) {
          case SegmentedTextType.PARAM_START:
          case SegmentedTextType.PARAM_END:
            return <React.Fragment key={key}></React.Fragment>;
          case SegmentedTextType.STATIC:
            return (
              <React.Fragment key={key}>
                {isSegmentEditing ? (
                  <span
                    {...editingProps}
                    className={`${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""
                      }`}
                    dangerouslySetInnerHTML={{ __html: breakedHTMLText }}
                  ></span>
                ) : (
                  // <span {...editingProps} className={isSelected ? "selected-text" : ""} >{text}</span>
                  // <span {...editingProps} className={isSelected ? "selected-text" : ""} >{breakedText}</span>
                  // <div {...editingProps} className={isSelected ? "selected-text" : ""} dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></div>
                  // <div {...editingProps} className={isSelected ? "selected-text" : ""} >{text}</div>
                  // <div {...editingProps} className={isSelected ? "selected-text" : ""} >{breakedText}</div>
                  <span
                    {...editableProps}
                    className={`${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""
                      }`}
                    dangerouslySetInnerHTML={{ __html: breakedHTMLText }}
                  ></span>
                )}
              </React.Fragment>
            );
          case SegmentedTextType.COMMENT:
            return (
              <React.Fragment key={key}>
                {isSegmentEditing ? (
                  <span
                    {...editingProps}
                    className={`comment comment-edit ${isHighlighted ? "highlighted-text" : ""
                      }`}
                    dangerouslySetInnerHTML={{ __html: breakedHTMLText }}
                  ></span>
                ) : (
                  <>
                    <span
                      className={`comment-prefix ${isHighlighted ? "highlighted-text" : ""
                        }`}
                    >
                      ☞ &nbsp;
                    </span>
                    <span
                      {...editableProps}
                      className={`comment ${isHighlighted ? "highlighted-text" : ""
                        }`}
                      dangerouslySetInnerHTML={{ __html: breakedHTMLText }}
                    ></span>
                  </>
                )}
              </React.Fragment>
            );
          case SegmentedTextType.PARAM:
            const paramPath = value.split(".");
            const param = segmentation.segmentedParams.find(
              (param) => param.name == segment.paramName
            );
            const paramLabel = param?.label;
            return (
              <span
                id={`param-${segment.paramName}`}
                key={key}
                className={`param ${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""
                  }`}
              >
                {paramLabel ?? value}
                {paramPath[1] &&
                  (param?.type == "beneficial" || param?.type == "beneficial[]") &&
                  `(${paramPath[1]})`}
              </span>
            );
          case SegmentedTextType.PARAM_VALUE:
            const paramX = segmentation.segmentedParams.find(
              (param) => param.name == segment.paramName
            );
            if (paramX && paramX?.type === "file") {
              return (
                <React.Fragment key={key}>
                  <span
                    onClick={() => {
                      openFilePreview(inputValues[paramX.name]);
                    }}
                    id={`param-${segment.paramName}`}
                    style={{ whiteSpace: "pre-line", cursor: "pointer" }}
                    className={`param-value ${isSelected ? "selected-text" : ""
                      } ${isHighlighted ? "highlighted-text" : ""}`}
                  >
                    {text}
                  </span>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={key}>
                  <span
                    id={`param-${segment.paramName}`}
                    style={{ whiteSpace: "pre-line" }}
                    className={`param-value ${isSelected ? "selected-text" : ""
                      } ${isHighlighted ? "highlighted-text" : ""}`}
                  >
                    {text}
                  </span>
                </React.Fragment>
              );
            }
          case SegmentedTextType.PARAM_TABLE_VALUE:
            const [transposed, tableRows] = JSON.parse(value);
            const [headers, ...rows] = tableRows as string[][];
            return (
              <TableRender
                transposed={transposed}
                headers={headers}
                rows={rows}
                isHighlighted={isHighlighted}
              />
            );

          case SegmentedTextType.PARAM_COMMENT_VALUE:
            return (
              <React.Fragment key={key}>
                <span
                  id={`param-${segment.paramName}`}
                  style={{ whiteSpace: "pre-line" }}
                  className={`comment ${isHighlighted ? "highlighted-text" : ""
                    }`}
                >
                  {text}
                </span>
              </React.Fragment>
            );
          default:
            break;
        }
      })}
      <FilePreviewModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        fileDocument={fileToPreview}
        text={text}
      />
    </div>
  );
}

export default RenderSegmentedText;
