import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { TypeLevel1Client } from "../../../services/api/TypeLevel1Client";
import { getName } from "../../../helpers/Translation";
import {
  FormTemplateEntity,
  TypeLevel1Entity,
  TypeLevel2Entity,
  TypeLevel3Entity,
} from "../../../domain/entities";
import { FormTemplateClient } from "../../../services/api/FormTemplateClient";
import { useForm } from "react-hook-form";
import CustomCombobox from "../../../components/common/CustomCombobox";
import PendingAi from "../../../components/common/newDesign/PendingAi";
import "../../../components/modals/commonmodal.scss";
function RessourceForm({
  onClose,
  loading,
  setLoading,
  setRessources,
  ressource,
  ressources=[]
}) {
  const [error, setError] = useState("");
  const [templateExistsError, setTemplateExistsError] = useState(false);
  const [checkTemplateExistsTimeout, setCheckTemplateExistsTimeout] =
    useState<NodeJS.Timeout>(null)

  const [isFormValid, setIsFormValid] = useState(false);
  const [req, setReq] = useState<FormTemplateEntity>({
    name: ressource?.name ? ressource.name : "",
  });
  const [required, setChecked] = useState(
    ressource ? !ressource.isOptional : true
  );

  const handleCheckboxChange = () => {
    setChecked(!required);
  };
  const [options, setOptions] = useState<{
    level1: TypeLevel1Entity[];
    level2: TypeLevel2Entity[];
    level3: TypeLevel3Entity[];
  }>({
    level1: [],
    level2: [],
    level3: [],
  });
  const { watch, register } = useForm();

  const { t, language } = useTranslation();
  const translationPath = t("pages.admin.actionsOptionProjectTemplate");

  const [openCombobox, setOpenCombobox] = useState<string | null>(null);

  const apiClient = useApiClientWithLoading();
  const formTemplateClient = new FormTemplateClient(apiClient);
  const typeLevel1Client = new TypeLevel1Client(apiClient);
  const handleSave = async (e) => {
    try {
      e.preventDefault();
      if (!isFormValid || templateExistsError) {
        setError("veuillez remplir tout les champs nécessaire");
      } else {
      
        const dataRequirement = {
          ...req,
          isOptional: !required,
          types: [8],
        };
        if (ressource.name) {
          setRessources((prev) =>
            prev.map((r) => (r.name== ressource.name ? dataRequirement : r))
          );

        } else {
          setRessources((prev) => {
            if (Array.isArray(prev)) {
              return [...prev, dataRequirement];
            } else {
              return [dataRequirement]; 
            }
          });
        }
        onClose();
      }
    } catch (err) {
      console.log("err :>> ", err);
    }
  };
  useEffect(() => {
    if (ressource?.name) {
      setReq({
        name: ressource.name || "",
        level1Id: ressource.level1Id,
        level2Id: ressource.level2Id,
        level3Id: ressource.level3Id,
        level1: ressource.level1,
        level2: ressource.level2,
        level3: ressource.level3
      })
    }
  }, [ressource]);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await typeLevel1Client.getAll();
      const selectedLevel1 = data.rows.find(
        (item) => item.id === ressource.level1Id
      );
      const levels2 = selectedLevel1?.levels2 ?? [];
      const selectedLevel2 = levels2?.find(
        (item) => item.id === ressource.level2Id
      );
      const levels3 = selectedLevel2?.levels3 ?? [];
      setOptions({
        level1: data.rows,
        level2: levels2,
        level3: levels3,
      });
    };
    setLoading(false);

    fetchData();
  }, []);
  const handleLevel1Change = (value) => {
    const selectedLevel1 = options.level1.find((item) => item.id === value);
    setReq({
      ...req,
      level1Id: selectedLevel1 ? selectedLevel1.id : null,
      level2Id: null,
      level3Id: null,
      level1:selectedLevel1,
      level2:null,
      level3:null
    });
    setOptions((prevOptions) => ({
      ...prevOptions,
      level2: selectedLevel1 ? selectedLevel1.levels2 : [],
      level3: [],
    }));
    setError("");
  };

  const handleLevel2Change = (value) => {
    const selectedLevel2 = options.level2.find((item) => item.id === value);
    setReq({
      ...req,
      level2Id: selectedLevel2 ? selectedLevel2.id : null,
      level3Id: null,
      level2: selectedLevel2,
      level3:null
    });
    setOptions((prevOptions) => ({
      ...prevOptions,
      level3: selectedLevel2 ? selectedLevel2.levels3 : [],
    }));
  };
  const handleLevel3Change = (value) => {
    const selectedLevel3 = options.level3.find((item) => item.id === value);
    setReq({
      ...req,
      level3Id: selectedLevel3 ? selectedLevel3.id : null,
      level3:selectedLevel3
    });
    setError("");
  };
  const validateForm = () => {
    const isValid =
      req.name !== "" &&
      !templateExistsError &&
      req.level1Id != null &&
      (options.level2.length === 0 || req.level2Id != null) &&
      (options.level3.length === 0 || req.level3Id != null);
    setIsFormValid(isValid);
  };

  useEffect(() => {
    validateForm();
  }, [req]);
  useEffect(() => {
    if (checkTemplateExistsTimeout) {
      clearTimeout(checkTemplateExistsTimeout);
    }
    setCheckTemplateExistsTimeout(
      setTimeout(async () => {
        try {
          if (req?.name) {
            const count = await formTemplateClient.countAllByFilter({
              name: String(req?.name),
            });
            setTemplateExistsError(count >= 1);
          } else {
            setTemplateExistsError(false);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setCheckTemplateExistsTimeout(null);
        }
      }, 500)
    );
  }, [req?.name]);

  return (
    <div>
      {loading ? (
        <PendingAi height="100%" />
      ) : (
        <form
          action=""
          className="form-group w-100 mb-3"
          style={{ maxHeight: "80vh" }}
          onSubmit={handleSave}
        >
          <p className="fw-bolder">
            {t("pages.listener.popups.contract.required")}
          </p>
          <div className="form-input-content">
            <div className="form-input-first-content" style={{ width: "100%" }}>
              <label htmlFor="contractName" className="case-form-labelName">
                {t("modals.generateFormTemplate.inputs.name")}{" "}
              </label>
              <div className="form-floating mb-3 w-100">
                <input
                  type="text"
                  className="form-control custom-color-input input-popup"
                  id="contractName"
                  value={req?.name}
                  placeholder={t(
                    "pages.listener.popups.contract.inputs.contractName.placeholder"
                  )}
                  {...register("templateName", { required: true })}
                  onChange={(e) => {
                    setReq({ ...req, name: e.target.value });
                    setError("");
                    setTemplateExistsError(false);
                  }}
                />
                {templateExistsError && (
                  <p className="text-danger py-0 my-0 py-0 my-0">
                    {t("modals.createBlankTemplate.unicityError")}
                  </p>
                )}
              </div>
            </div>
            <div className="form-input-last-content" style={{ width: "80%" }}>
              <CustomCombobox
                fullWidth
                label={t("pages.pilot.popups.contract.inputs.type1.name")}
                options={options.level1.map((item) => item)}
                optionDisplay={(option) => getName(option, language)}
                optionValue={(option) => option?.id ?? null}
                value={options.level1.find(
                  (option) => option.id == req.level1Id
                )}
                onChange={(value) => {
                  handleLevel1Change(value);
                }}
                isOpen={openCombobox === "level1"}
                setIsOpen={(isOpen) =>
                  setOpenCombobox(isOpen ? "level1" : null)
                }
              />

              {(!req.level1Id || options.level2.length > 0) && (
                <CustomCombobox
                  fullWidth
                  label={t("pages.pilot.popups.contract.inputs.type2.name")}
                  options={options.level2.map((item) => item)}
                  optionDisplay={(option) => getName(option, language)}
                  optionValue={(option) => option?.id ?? null}
                  value={options.level2.find(
                    (option) => option.id == req.level2Id
                  )}
                  onChange={(value) => {
                    handleLevel2Change(value);
                  }}
                  isOpen={openCombobox === "level2"}
                  setIsOpen={(isOpen) =>
                    setOpenCombobox(isOpen ? "level2" : null)
                  }
                />
              )}
              {(!req.level1Id ||
                (options.level2.length > 0 && !req.level2Id) ||
                options.level3.length > 0) && (
                <CustomCombobox
                  fullWidth
                  label={t("pages.pilot.popups.contract.inputs.type3.name")}
                  options={options.level3.map((item) => item)}
                  optionDisplay={(option) => getName(option, language)}
                  optionValue={(option) => option?.id ?? null}
                  value={options.level3.find(
                    (option) => option.id == req.level3Id
                  )}
                  onChange={(value) => {
                    handleLevel3Change(value);
                  }}
                  isOpen={openCombobox === "level3"}
                  setIsOpen={(isOpen) =>
                    setOpenCombobox(isOpen ? "level3" : null)
                  }
                />
              )}
            </div>
          </div>
          <p>
            <label className="case-form-labelName">
              {" "}
              {translationPath.required}{" "}
            </label>{" "}
            <input
              type="checkbox"
              checked={required}
              value={required.toString()}
              onChange={handleCheckboxChange}
              id="checkbox-required"
            />
          </p>
          {!isFormValid && error && (
            <p style={{ color: "red", fontSize: "13px" }}>{error}</p>
          )}
          <div className="modal-footer footer-form-pilotage">
            <button type="submit" className="btn btn-primary btn-display">
              {ressource.name ? "Editer " : translationPath.form.btn}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default RessourceForm;
