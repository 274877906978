import { useContext, useTransition } from "react";
import InputValuesContext from "../contexts/InputValuesContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import useApiClientWithLoading from "../services/api/ApiClient";
import { toast } from "react-toastify";
import { LoadingContext } from "../contexts/LoadingContext";
import { ContractEntity } from "../domain/entities";
import { numberToEnumList } from "../domain/Project";
import { ContractClient } from "../services/api/ContractClient";
import { ProjectClient } from "../services/api/ProjectClient";
import { useTranslation } from "../contexts/TranslationProvider";

export function useSubmitContract(navigateTo = '') {
  const { t } = useTranslation()
  const { setLoading } = useContext(LoadingContext);
  const { setContractTemplate, setAdditionalClauses, setInputValues, setTemplateId, setGroups, setContractId, setContract, project, requirementType, requirement } =
    useContext(InputValuesContext);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const restrictedToken = searchParams.get("restrictedToken")

  const apiClient = useApiClientWithLoading(setLoading, "application/json");
  const submitContract = async (body: ContractEntity, contractId: ContractEntity['id']) => {
    try {
      const contractClient = new ContractClient(apiClient)
      const projectClient = new ProjectClient(apiClient)

      let submitedContractId: ContractEntity['id']
      if (contractId) {
        const row = !restrictedToken ?
          await contractClient.update(contractId, body)
          : await contractClient.updateRestricted(contractId, body, restrictedToken)
        submitedContractId = row.id
        toast.success(t("pages.contracts.updating.messages.success"));
          navigate(-1);

      } else {
        const row = await contractClient.create(body);
        submitedContractId = row.id
        if (project) {
          const row = await projectClient.update(project?.id, {
            values: {
              ...project?.values,
              [requirement.id]: {
                type: numberToEnumList(requirementType)[0],
                id: submitedContractId,
              },
            },
            excludedRequirements: [...project.excludedRequirements]
          });
          navigate(`/projet/${row.id}`)
        }
        else {
          navigate("/ressources");
        }
        toast.success(t("pages.contracts.creating.messages.success"));
      }

      setInputValues({});
      setTemplateId(null);
      setGroups([]);
      setContractTemplate(null)
      setAdditionalClauses([])
      setContractId(null);
      setContract(null)
      return submitedContractId
    } catch (error) {
      toast.error(contractId ? t("pages.contracts.updating.messages.error") : t("pages.contracts.creating.messages.error"));
    }
  };

  return submitContract;
}
