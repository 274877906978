import React, { useEffect, useState } from "react";
import "./contractAnalysis.scss"
import { useContractData } from "../../../hooks/useContractData";
import Loading from "../../common/Loading";
import { mergeParams } from "../../../helpers/ParseTemplateData";
import InputList from "./InputList";
import { useTranslation } from "../../../contexts/TranslationProvider";

function ContractAnalysisModal({
    onClose,
    currentContractId,
}: {
    onClose: () => void;
    currentContractId: number;
}) {
    const [params, setParams] = useState([])
    const {
        templateData,
        isLoading,
        contractName,
        isEditing,
        paramValues,
        excludedClauses,
        excludedSubClauses,
    } = useContractData(
        currentContractId,
        undefined,
        undefined,
    );

    useEffect(() => {
        if (!currentContractId) return
        setParams(mergeParams(templateData))
    }, [currentContractId])
    const {t, language}=useTranslation()
    return (
        <>
            <>
                <div className="modal-backdrop"></div>
                <div id="contractz-lab">
                    <div className="modal d-flex justify-content-center align-items-center">
                        <div className="clause-comparison-modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{t("modals.contractAnalysis.title")}</h5>
                                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"
                                style={{marginRight:language=="ar"&&"85%"}}
                                ></button>
                            </div>
                            <div className="body-comparison">
                                {isLoading ? (
                                    <div className="contract-loading-container">
                                        <Loading height="90vh" />
                                    </div>
                                ) : (<>
                                <h5>{contractName}</h5>
                                    <InputList
                                        templateData={templateData}
                                        contractName={contractName}
                                        isEditing={isEditing}
                                        paramValues={paramValues}
                                        initialExcludedClauses={excludedClauses}
                                        initialExcludedSubClauses={excludedSubClauses} />
                                        </>)}

                            </div>
                        </div>

                    </div>
                </div>
            </>
        </>
    );
}

export default ContractAnalysisModal;
