import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProjectDocument from "./ProjectDocument";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { enumToString } from "../../domain/Project";
import "./project.scss";
import { FiEdit2 } from "react-icons/fi";
import { UserEntity } from "../../domain/entities";
import { useTranslation } from "../../contexts/TranslationProvider";
import { ProjectClient } from "../../services/api/ProjectClient";
import { UserClient } from "../../services/api/UserClient";
import EditProjectContext from "../../contexts/EditProjectContext";
import RquirementRessourceCard from "./RquirementRessourceCard";
import RessourceRequired from "./RessourceRequired";
import { ProjectTemplateRequirementType } from "../../domain/Project";
// import CreateContractModal from './RessourceModals/CreateContractModal'
import CreateFormModal from "./RessourceModals/CreateFormModal";
import ImportDocumentModal from "./RessourceModals/ImportDocumentModal";
import { FormClient } from "../../services/api/FormClient";
import { ContractClient } from "../../services/api/ContractClient";
import { ContractAIClient } from "../../services/api/ContractAIClient";
import { DocumentClient } from "../../services/api/DocumentClient";
import CreateContractModal from "./RessourceModals/CreateContractModal";
import projectSVG from "../../assets/new-design-icons/Projects.svg";
const lookup = require("../../assets/lookup.png")

const Project = () => {
  const { t, language, setLanguage } = useTranslation();
  const projectIcon = projectSVG.toString();
  const { project, setProject } = useContext(EditProjectContext);
  const [users, setUsers] = useState<UserEntity[]>([]);
  const [openDocPopup, setOpenDocPopup] = useState<number | null>(null);
  const [openContractPopup, setOpenContractPopup] = useState<number | null>(
    null
  );
  const [openFormPopup, setOpenFormPopup] = useState<number | null>(null);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const apiClient = useApiClientWithLoading();
  const projectClient = new ProjectClient(apiClient);
  const userClient = new UserClient(apiClient);
  const formClient = new FormClient(apiClient);
  const contractClient = new ContractClient(apiClient);
  const contractAIClient = new ContractAIClient(apiClient);
  const documentClient = new DocumentClient(apiClient);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };
  useEffect(() => {
    if (projectId) {
      (async () => {
        const row = await projectClient.getById(parseInt(projectId));
        const excludedRequirements = row.excludedRequirements ?? [];
        const updatedRequirements = row?.template?.requirements?.filter(
          (req) => {
            return !excludedRequirements.includes(req.id);
          }
        );
        const updatedProject = {
          ...row,
          template: {
            ...row?.template,
            requirements: updatedRequirements,
          },
        };
        setProject(updatedProject);
        const arabicRegex = /[\u0600-\u06FF]/;
        if (arabicRegex.test(row.name)) {
          setLanguage("ar");
        }
      })();
    }
  }, [projectId]);
  const getNextResource = (type, idDocument) => {
    if (type == "CONTRACTAI") {
      return project?.linkedRessources["DOCUMENT"]?.find((item) => {
        return idDocument?.id === item.id;
      });
    }
    const ressource = project?.linkedRessources[type]?.find((item) => {
      return idDocument?.id === item.id;
    });
    return ressource;
  };
  const onUpdateProject = (idToRemove) => {
    setProject((prevProject) => {
      const updatedProject = { ...prevProject };
      updatedProject.values = Object.fromEntries(
        Object.entries(updatedProject.values).filter(
          ([key, value]) => value.id !== idToRemove
        )
      );
      return updatedProject;
    });
  };
  const onClose = (id) => {
    setOpenDocPopup(null);
    setOpenContractPopup(null);
    setOpenFormPopup(null);
    (async () => {
      const row = await projectClient.getById(parseInt(projectId));
      const excludedRequirements = row.excludedRequirements ?? [];
      const updatedRequirements = row?.template?.requirements?.filter((req) => {
        return !excludedRequirements.includes(req.id);
      });
      const updatedProject = {
        ...row,
        template: {
          ...row?.template,
          requirements: updatedRequirements,
        },
      };
      setProject(updatedProject);
      const arabicRegex = /[\u0600-\u06FF]/;
      if (arabicRegex.test(row.name)) {
        setLanguage("ar");
      }
    })();
  };
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const usersResponse = await userClient.getAll();
        setUsers(usersResponse?.rows);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    fetchUser();
  }, []);
  const handleDeleteDocument = async (id, type) => {
    const valueKey = Object.keys(project?.values).find(
      (key) => project?.values[key]?.id === id
    );
    if (valueKey) {
      const updatedValues = { ...project?.values };
      delete updatedValues[valueKey];
      const updatedProject = await projectClient.update(project?.id, {
        values: updatedValues,
        excludedRequirements: [],
      });
      onUpdateProject(id);
      switch (type) {
        case "FORM":
          await formClient.delete(id);
          break;
        case "CONTRACT":
          await contractClient.delete(id);
          break;
        case "CONTRACTAI":
          await contractAIClient.delete(id);
          break;
        case "DOCUMENT":
          await documentClient.delete(id);
          break;
      }
    } else {
      console.log(`Value with id ${id} not found in the project's values.`);
    }
  };
  return (
    <div className="project px-3 pt-2 w-100">
      <section id="display-project">
        <div className="container-paths">
          <span
            className="previous-pathname"
            onClick={() => navigate("/projets")}
          >
            {t("sidebar.myProjects")}
          </span>
          <span className="path-separator"> {">"} </span>
          <span className="current-pathname">{project?.name}</span>
        </div>
        <div className="project-header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <img
              src={projectIcon}
              alt="project"
              style={{ width: "40px", height: "40px" }}
            />
            <h3 className="project-title" style={{ marginTop: "10px" }}>
              {project?.name}
            </h3>
          </div>
          <FiEdit2
            style={{ cursor: "pointer", fontSize: "14px" }}
            color={"gray"}
            onClick={() => {
              navigate(`/edit-project/${project?.id}`);
            }}
          />
        </div>
        <p className="project-description">{project.description}</p>
        <div className="search-container">
          <input
            type="text"
            placeholder={t("pages.projects.searchPlaceholder")}
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
          <img src={lookup} alt="#" className={`lookup-img ${language == "ar" && "lookup-img-arabic"}`} />

        </div>
        <div className="document-container">
          {project?.template?.requirements?.filter(req => req?.name.toLowerCase().includes(searchTerm))?.map((req, index) => {
            let type =
              project?.values && enumToString(project?.values[req?.id]?.type);
            const ressource = getNextResource(type, project?.values[req?.id]);
            if (!ressource) {
              if (
                req?.types?.includes(ProjectTemplateRequirementType.DOCUMENT)
              ) {
                type = "DOCUMENT";
              } else if (
                req?.types?.includes(ProjectTemplateRequirementType.CONTRACT)
              ) {
                type = "CONTRACT";
              } else if (
                req?.types?.includes(ProjectTemplateRequirementType.FORM)
              ) {
                type = "FORM";
              } else {
                type = "CONTRACTAI";
              }
            }
						const isFileMatch = ressource && req.name.toLowerCase().includes(searchTerm);
            return (
              <>
                {openDocPopup && openDocPopup === req.id && (
                  <ImportDocumentModal
                    onClose={() => onClose(req.id)}
                    dataProject={{ requirement: req, project: project }}
                    typeReq={ProjectTemplateRequirementType.DOCUMENT}
                  />
                )}
                {openFormPopup && openFormPopup === req.id && (
                  <CreateFormModal
                    onClose={() => onClose(req.id)}
                    dataProject={{ requirement: req, project: project }}
                    typeReq={ProjectTemplateRequirementType.FORM}
                  />
                )}
                {openContractPopup && openContractPopup === req.id && (
                  <CreateContractModal
                    onClose={() => onClose(req.id)}
                    dataProject={{ requirement: req, project: project }}
                    typeReq={ProjectTemplateRequirementType.CONTRACT}
                  />
                )}
								{(isFileMatch || !ressource) ? (
                ressource ? (
                  <RquirementRessourceCard
                    data={{
                      ...ressource,
                      type: type,
                      level1: req?.level1,
                      level2: req?.level2,
                      level3: req?.level3,
                    }}
                    onClick={() => {
                      if (type === "DOCUMENT") {
                        navigate(`/document/${ressource.id}`);
                      }
                      if (type === "CONTRACT") {
                        navigate(`/edition-contrat/${ressource.id}`, {
                          state: {
                            previousPathname: [
                              {
                                link: "/projets",
                                label: t("sidebar.myProjects"),
                              },
                              {
                                link: `/projet/${project?.id}`,
                                label: project?.name,
                              },
                            ],
                          },
                        });
                      }
                      if (type === "FORM") {
                        navigate(`/edit-form/${ressource.id}`, {
                          state: {
                            previousPathname: [
                              {
                                link: "/projets",
                                label: t("sidebar.myProjects"),
                              },
                              {
                                link: `/projet/${project?.id}`,
                                label: project?.name,
                              },
                            ],
                          },
                        });
                      }
                      if (type === "CONTRACTAI") {
                        navigate(`/document-summary/${ressource.id}`);
                      }
                    }}
                    onDelete={() => {
                      handleDeleteDocument(ressource.id, type);
                    }}
                  />
                ) : (
                  <RessourceRequired
                    data={{
                      type,
                      name: req?.name,
                      level1: req?.level1,
                      level2: req?.level2,
                      level3: req?.level3,
                    }}
                    onClick={() => {
                      if (type === "DOCUMENT") {
                        setOpenDocPopup(req.id);
                      }
                      if (type === "CONTRACT" || type === "CONTRACTAI") {
                        setOpenContractPopup(req.id);
                      }
                      if (type === "FORM") {
                        setOpenFormPopup(req.id);
                      }
                    }}
                    onDelete={() => {}}
                  />
                )
							 ) :null} 
              </>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Project;
