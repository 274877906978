import "./sidebar.scss";
import { useNavigate } from "react-router-dom";
import MiddlewareManager from "../../../services/MiddlewareManager";
import SVGRender from "../../../helpers/SVGRender";

const CustomNavLink = ({ className = "", style = {}, to, children }) => {
  const navigate = useNavigate();

  const handleNavigation = async () => {
    await MiddlewareManager.invoke('location', to);
    navigate(to);
  };
  return (
    <a
      className={className}
      style={{ ...style, cursor: "pointer", textDecoration: "none" }}
      onClick={handleNavigation}
      hrefLang=""
    >
      {children}
    </a>
  );
};
function SidebarItem({ isHovering, to, location, text, icon }) {

  return (
    <CustomNavLink
      to={to}
      style={{ textDecoration: "none" }}
      className={`d-flex gap-3 sidebar-item${location === to ? " located" : ""}`}
    >
      <div className={`half-border-bottom${location === to ? " located-border" : ""}`}>
        <SVGRender svgContent={icon} color={location === to ? "#fff" : "#000"} />
        {isHovering ? <div className={"sidebar-item-text" + location === to ? " located" : ""}>{text}</div> : ''}
      </div>
    </CustomNavLink>
  );
}

export default SidebarItem;
