import { createContext } from "react";
import { FormEntity, FormTemplateEntity, FormTemplatePageEntity, GroupEntity, ProjectEntity, ProjectTemplateRequirementEntity } from "../domain/entities";
import { BeneficialEntity } from "../domain/entities";
import { FormParam } from "../domain/types/FormParams";


type SelectedFormTemplatePageType = {
  pageId: FormTemplatePageEntity['id'];
  paramName?: FormParam['name'];
}
export interface EditFormContextType {
  form: FormEntity;
  setForm: React.Dispatch<React.SetStateAction<FormEntity>>;
  formId: FormEntity['id'],
  setFormId: React.Dispatch<React.SetStateAction<FormEntity['id']>>;
  template: FormTemplateEntity;
  setTemplate: React.Dispatch<React.SetStateAction<FormTemplateEntity>>;
  templateId: FormTemplateEntity['id'],
  setTemplateId: React.Dispatch<React.SetStateAction<FormTemplateEntity['id']>>;
  name: FormEntity['name'];
  setName: React.Dispatch<React.SetStateAction<FormEntity['name']>>;
  paramValues: FormEntity['paramValues'];
  setParamValues: React.Dispatch<React.SetStateAction<FormEntity['paramValues']>>;
  fileNames: FormEntity['fileNames'];
  setFileNames: React.Dispatch<React.SetStateAction<FormEntity['fileNames']>>;
  beneficialsMap: Record<string, BeneficialEntity>;
  setBeneficialsMap: React.Dispatch<React.SetStateAction<FormEntity['beneficialsMap']>>;
  groups: GroupEntity[];
  setGroups: React.Dispatch<React.SetStateAction<GroupEntity[]>>;
  completionPercentage: number,
  setCompletionPercentage: React.Dispatch<React.SetStateAction<number>>
  selected: SelectedFormTemplatePageType;
  setSelected: React.Dispatch<React.SetStateAction<SelectedFormTemplatePageType>>;
  project?: ProjectEntity
  setProject?: React.Dispatch<React.SetStateAction<ProjectEntity>>;
  requirement?: ProjectTemplateRequirementEntity,
  setRequirement?: React.Dispatch<React.SetStateAction<ProjectTemplateRequirementEntity>>
  requirementType?: number,
  setRequirementType?: React.Dispatch<React.SetStateAction<number>>
}

const EditFormContext = createContext<EditFormContextType>({} as any);

export default EditFormContext;
