export function groupByUnique<T>(array: T[], key: (keyof T) | ((item: T) => keyof T)) {
    let out = {}
    for (const item of array) {
        let realKey
        if ((typeof key == "function"))
            realKey = key(item)
        else
            realKey = key
        out[realKey] = item
    }
    return out
}
export function intersectionByKey<T>(arrayA: T[], arrayB: T[], key: keyof T) {
    return arrayA.filter((itemA) => arrayB.find((itemB) => itemB[key] === itemA[key]));
}
export function initArray<T>(value: T, length: number) {
    let array: T[] = []
    for (var i = 0; i < length; ++i)
        array.push(value)
    return array

}
export function transpose<T>(matrix: T[][]) {
    if (matrix.length === 0) {
        return [];
    }

    const numRows = matrix.length;
    const numCols = matrix[0].length;

    // Initialize the transposed matrix with the correct dimensions
    const transposed: T[][] = new Array(numCols).fill(null).map(() => new Array(numRows));

    for (let i = 0; i < numRows; i++) {
        for (let j = 0; j < numCols; j++) {
            transposed[j][i] = matrix[i][j];
        }
    }

    return transposed;
}

export function removeDuplicates<T, K = string & keyof T>(array: T[], key: K): T[] {
    const uniqueMap: Record<string, boolean> = {};

    return array.filter(item => {
        const keyValue = item[key as any];
        if (!(keyValue in uniqueMap)) {
            uniqueMap[keyValue] = true
            return true;
        }
        return false;
    });
}
