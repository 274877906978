import { FormEntity, FormTemplateEntity } from "./entities";
import { FormParam } from "./types/FormParams";


var counter = 0
export const genIdFactory = (code: string) => {
	counter = 0
	return () => `${code}-${++counter}`
}

type RawFormParam = {
	type: FormParam['type']
	| "beneficialCompany"
	| "beneficialPerson"
	| "beneficialCompany[]"
	| "beneficialPerson[]"
	;
	name: FormParam['name']
	label: FormParam['label'],
	args: string;
	condition: string;
}

export function ParseRawParam(rawParam: RawFormParam): FormParam {
	const { name, label, type } = rawParam
	let rawArgs = rawParam.args.slice(1, -1).split("][")
	const rawCondition = rawParam.condition?.slice(1, -1).split("][").filter(str => str).map(str => str.split('&'))
	const condition: FormParam['condition'] = rawCondition?.length ?
		rawCondition.map(andCond => andCond.map(cond => {
			const parts = cond.split(":")
			return {
				name: parts[0],
				value: parts[1] ?? 0,
			}
		})) : undefined
	let param: FormParam
	switch (type) {
		case 'beneficial':
		case 'beneficialCompany':
		case 'beneficialPerson':
			param = {
				type: 'beneficial', name, label, condition,
				args: {
					beneficialTypes: ['Person', 'Company']
				}
			}
			if (type == 'beneficialCompany') param.args.beneficialTypes = ['Company']
			if (type == 'beneficialPerson') param.args.beneficialTypes = ['Person']
			return param
		case 'beneficial[]':
		case 'beneficialCompany[]':
		case 'beneficialPerson[]':
			param = {
				type: 'beneficial[]', name, label, condition,
				args: {
					beneficialTypes: ['Person', 'Company'],
				}
			}
			if (type == 'beneficialCompany[]') param.args.beneficialTypes = ['Company']
			if (type == 'beneficialPerson[]') param.args.beneficialTypes = ['Person']
			return param
		case 'comment':
		case 'date':
		case 'file':
		case 'number':
		case 'string':
		case 'boolean':
		case 'csv':
			return { type: type as any, name, label, condition }
		case 'enum':
			return {
				type: 'enum', name, label, condition,
				args: rawArgs.map(rawArg => {
					const parts = rawArg.split(":")
					const [option, ...textParts] = parts
					return { option }
				})
			}
		case 'list':
			return {
				type, name, label, condition,
				args: rawArgs.map(rawArg => {
					return rawArg.startsWith("!") ? { option: rawArg.slice(1), required: true } : { option: rawArg, required: false }
				})
			}
		case 'table':
			const transposed = rawArgs[0] == 'T'
			return {
				type, name, label, condition,
				transposed: transposed,
				args: (transposed ? rawArgs.slice(1) : rawArgs).map(rawArg => {
					const [header, type] = rawArg.split(":").map(str => str.trim())
					return { header, type } as any
				})

			}
		default:
			const exhaustiveCheck: never = type;
			return exhaustiveCheck

	}
}
export function FormParamToRaw(param: FormParam): RawFormParam {
	const condition = param.condition?.map(andCond =>
		`[${andCond.map(cond =>
			`${cond.name}:${cond.value}`
		).join('&')
		}]`
	).join("") ?? ""
	switch (param.type) {
		case 'comment':
		case 'date':
		case 'file':
		case 'number':
		case 'string':
		case 'boolean':
		case 'csv':
			return {
				type: param.type as any,
				name: param.name,
				label: param.label,
				condition,
				args: "",
			}
		case 'beneficial':
			if (!param.args.beneficialTypes)
				param.args = { beneficialTypes: ['Company', 'Person'] }
			return {
				name: param.name,
				label: param.label,
				condition,
				args: "",
				type: (param?.args.beneficialTypes.length == 2) ?
					'beneficial'
					: param?.args.beneficialTypes.includes('Company') ?
						'beneficialCompany'
						: 'beneficialPerson' as any,
			}
		case 'beneficial[]':
			if (!param.args.beneficialTypes)
				param.args = { beneficialTypes: ['Company', 'Person'] }
			return {
				name: param.name,
				label: param.label,
				condition,
				args: "",
				type: (param?.args.beneficialTypes.length == 2) ?
					'beneficial[]'
					: param?.args.beneficialTypes.includes('Company') ?
						'beneficialCompany[]'
						: 'beneficialPerson[]' as any,
			}
		case "enum":
			return {
				type: param.type as any,
				name: param.name,
				label: param.label,
				condition,
				args: param.args.map(arg => `[${arg.option}]`).join("")
			}
		case 'list':
			return {
				type: param.type as any,
				name: param.name,
				label: param.label,
				condition,
				args: param.args.map(arg => `[${arg.required ? "!" : ""}${arg.option}]`).join("")
			}
		case 'table':
			return {
				type: param.type as any,
				name: param.name,
				label: param.label,
				condition,
				args: [
					...(param.transposed ? ["[T]"] : []),
					...param.args.map(arg => `[${arg.header}:${arg.type}]`)
				].join("")
			}
	}
}
export function getAllParams(template: FormTemplateEntity) {
	const params: FormParam[] = []
	template?.pages?.forEach((page) => {
		params.push(...(page.params ?? []))
	})
	return params
}
export function getAllRenderedParams(params: FormParam[], inputValues: Required<FormEntity>['paramValues']): FormParam[] {
	const renderedParams: FormParam[] = []
	params?.forEach(param => {
		const condition = param.condition
		if (!condition || !condition.length)
			return renderedParams.push(param)
		for (const andCond of condition) {
			let result: boolean = true
			for (const cond of andCond) {
				result = result && (
					inputValues[cond.name] == cond.value
				)
			}
			if (result)
				return renderedParams.push(param)
		}
	})
	return renderedParams
}

export function orderRenderedParams(renderedParams: FormParam[]): FormParam[] {
	const orderedParams: FormParam[] = []
	const highLevelParams = renderedParams.filter(param => !param.condition || !param.condition.length)
	const lowLevelParams = renderedParams.filter(param => param.condition && param.condition.length)
	highLevelParams.forEach(param => {
		orderedParams.push(param)
		const dependantParams = lowLevelParams.filter(p => p.condition?.some(andCond => andCond.some(cond => cond.name == param.name)))
		dependantParams.forEach(p => {
			orderedParams.push(p)
		})
	})
	return orderedParams
}
export function getAllRenderedParamNames(params: FormParam[], inputValues: Required<FormEntity>['paramValues']): string[] {
	return getAllRenderedParams(params, inputValues).map(param => param.name)
}

export function ParseRawParams(rawParams: RawFormParam[]): FormParam[] {
	return rawParams.map(p => ParseRawParam(p))
}
export type ValidationWarning = {
	message: string;
	templateCode: string;
	pageCode: string;
	paramName: string;
}

export function ParseRawFormTemplate(data: FormTemplateEntity) {
	data.pages?.forEach(page => {
		if (page.params?.length) {
			page.params = ParseRawParams(page.params as any)
		}
	})
}
