import React, { useEffect, useState } from "react";
import { useTranslation } from '../../../../contexts/TranslationProvider';
import '../buttons.scss'
import CreateContractModal from "../../../modals/CreateContractModal";

function MigrateToContract() {
  const [showPopUp, setShowPopup] = useState(false);
  const { t } = useTranslation();
  const [formId, setFormId] = useState(null);

  useEffect(() => {
    const path = window.location.pathname;
    const id = path.split("/").pop();
    const parsedId = parseInt(id, 10);
    if (!isNaN(parsedId)) {
      setFormId(parsedId);
    } else {
      console.error('Invalid form ID:', id);
    }
  }, []);

  return <>
    <button className="navbar-button button-migrate" onClick={()=>setShowPopup(!showPopUp)} style={{position:"relative"}}>
    {t("pages.editionForm.exportToContract")}
    </button>
    {showPopUp && (
        <CreateContractModal
        formId={formId}
        migrateToContract={true}
          onClose={() => setShowPopup(false)}
        />)}
  </>
}

export default MigrateToContract;
