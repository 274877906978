import React, { useState } from "react";
import "./customCombobox.scss";
import { BsChevronDown, BsX } from "react-icons/bs";
import { useTranslation } from "../../../contexts/TranslationProvider";


interface CustomComboboxProps<O, V = O> {
  options: O[];
  value: O;
  isValid? : boolean
  onChange: (value: V) => void;
  label: String;
  canDeselect?: boolean;
  onDropdownOpen?: () => void;
  optionDisplay?: (option: O) => any;
  optionValue?: (option: O) => V;
  onClick?: () => void;
  optionIsEnabled?: (option: O) => boolean;
  fullWidth?: boolean;
  isOpen?:boolean,
  setIsOpen?: (isOpen: boolean) => void,
}

function CustomCombobox<O, V>({
  options,
  isValid = true, 
  value,
  onChange,
  label,
  onClick,
  canDeselect,
  onDropdownOpen,
  optionIsEnabled,
  optionDisplay,
  optionValue,
  isOpen=false,
  setIsOpen,
  fullWidth }: CustomComboboxProps<O, V>) {
  const { t, language } = useTranslation(); 

  const handleOptionClick = (option) => {
    const value = optionValue ? optionValue(option) : option;
    setIsOpen(false);
    onChange(value);
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation(); // Add this line to stop event propagation
    setIsOpen(!isOpen);
    if (!isOpen) {
      onDropdownOpen?.();
    }
  };

  const handleClearClick = (event) => {
    event.stopPropagation(); // Add this line to stop event propagation
    onChange(null);
  };

  const currentOptionDisplay: String = optionDisplay ? optionDisplay(value) : value as any;

  return (
    <div className="custom-combobox-main mb-3" style={fullWidth ? { width: '100%' } : null}>
      {label && <label className="custom-combobox-label">{label}</label>}
      <div className={`custom-combobox-container ${isValid ? "" : "invalid"}`} onClick={onClick}>
        <div className="custom-combobox">
          <div className="custom-combobox-selected" onClick={handleDropdownClick}>
            {currentOptionDisplay}
            {!isOpen && canDeselect && value && (
              <BsX
                color="#ff0000" // Set the color for the X icon
                style={{ position: "absolute", cursor: "pointer", right: language == "ar" ? "auto" : "30px", left: language == "ar" ? "30px" : "auto"}}
                onClick={handleClearClick}
              />
            )}
            <BsChevronDown color="#2f14e5" />
          </div>
          {isOpen && (
            <div className="custom-combobox-options">
              {options.map((option, index) => {
                const key = `${label}_${index}`;
                const enabled = optionIsEnabled ? optionIsEnabled(option) : true;
                const display = optionDisplay ? optionDisplay(option) : option as any as String;
                return (
                  <div key={key} className={enabled ? "custom-combobox-option" : "custom-combobox-option-disabled"} onClick={() => enabled && handleOptionClick(option)}>
                    {display}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomCombobox;
