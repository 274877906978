import React, {useState, useContext, useMemo, useEffect} from 'react'
import EditFormContext from '../../../contexts/EditFormContext';
import { useTranslation } from '../../../contexts/TranslationProvider';
import FormParameterInput from './FormParameterInput';
function FormNestedChildrenParams({param, page, setShowBeneficial, handleInputChange, triggeredParam}) {
  const {
    paramValues, setParamValues,
    selected, setSelected,
    beneficialsMap,
    fileNames,
    template,
    name
  } = useContext(EditFormContext);
  const [showAddParam, setShowAddParam] = useState(false);
  const { setLanguage, language, t } = useTranslation();
  const editionTemplateTranslation = "pages.editionFormTemplate"
  const paramValue = useMemo(() => paramValues[param.name], [paramValues, param]);
  const nestedParams = useMemo(() => {
    const currentPage = template.pages.find(p => p.id === page.id);
    if(!currentPage) return [];
    const conditionalParams = currentPage.params
    .filter(p => p.condition && p.condition[0]?.find(c => c.name === param.name && (c.value as any) === paramValue));
    return conditionalParams;
  }, [param, template, page, paramValue]);
  const [nestedList, setList] = useState(nestedParams);
  useEffect(() => {
    setList(nestedParams);
  }, [nestedParams]);

  return (
    <div>
            <div 
            className='nested-param-list' 
            >
            {
              paramValue !== undefined && nestedList.map((nestedParam, index) => {
                return (
                    <div 
                    className=''
                    >
                  <FormParameterInput
                    param={nestedParam}
                    handleInputChange={handleInputChange}
                    triggeredParam={triggeredParam}
                    page={page}
                    setShowBeneficial={setShowBeneficial}
            />
                  </div>
                )
              })
            }
            </div>
    </div>
  )
}

export default FormNestedChildrenParams