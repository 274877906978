import React, { useState, useContext } from 'react'
import { getSubClauseName } from "../../../helpers/ParseTemplateData";
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
const { default: EditIcon } = require("../../../assets/edit_icon.svg");
const { default: EditIconOn } = require("../../../assets/edit_icon_on.svg");

function SubClauseHeader({ subClauseName, subClauseIndex, clauseIndex, clauseId, subClauseId }) {
  const [isEditing, setIsEditing] = useState(false)
  const [newName, setNewName] = useState(subClauseName)
  const handleNameChange = () => {
    console.log(clauseId, subClauseId, newName)
    if (newName === subClauseName) return;
    onClauseNameChange(clauseId, subClauseId, newName);
  }
  const {
    onClauseNameChange
  } = useContext(EditContractTemplateContext);
  return (
    <div className='subclause-header'>
      {!isEditing ? <>
        <h4 className="input-group-subtitle text-start py-2">
          {getSubClauseName(subClauseName, subClauseIndex, clauseIndex)}
        </h4>
        <img src={EditIcon} className='subclause-header-edit' alt="edit" onClick={() => setIsEditing(true)} />
      </> :
        <>
          <input
            type="text"
            value={newName}
            style={{ height: "25px" }}
            onChange={(e) => setNewName(e.target.value)}
            onBlur={handleNameChange}
          />
          <img src={EditIconOn} className='subclause-header-edit' alt="edit" onClick={() => setIsEditing(false)} />
        </>

      }
    </div>
  )
}

export default SubClauseHeader