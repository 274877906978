import moment from "moment";
import { LanguageType } from "../contexts/TranslationProvider";
import { BeneficialCompanyEntity, BeneficialEntity, BeneficialMinorEntity, BeneficialPersonEntity } from "../domain/entities";
import { getCountryByA3 } from "../utils/country";
import { getCurrencyByCode } from "../utils/currency";
import { getDeepValue } from "../utils/object";
import { COMPANY_CODE } from "../config";
import { evalWithObject } from "../utils/string";

const companyCode = COMPANY_CODE || 'default'

const tFromTranslations = (key, translations) => {
  const keys = key.split('.');
  let result = translations;

  for (const k of keys) {
    if (result && result[k] !== undefined) {
      result = result[k];
    } else {
      return key;
    }
  }

  return result;
}

export const virtualbeneficialGetters = {
  honorific(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType) {
    const translations = require(`../../templates/${companyCode}/${language}.json`)
    return tFromTranslations(`beneficialHonorific.${beneficial.gender}`, translations)
  },
  nationality(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType) {
    const country = getCountryByA3(beneficial.nationality || 'FRA')
    return country[`demonym_${language}`] || country.demonym_en
  },
  maritalStatus(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType) {
    if (beneficial?.maritalStatus == 'Other')
      return ""
    return t(`pages.editionContract.popups.benificial.options.maritalStatusOptions.${beneficial?.maritalStatus}`)
  },
  socialCapitalCurrency(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType) {
    if (beneficial.type == 'Company') {
      const currency = getCurrencyByCode(beneficial.socialCapitalCurrency || 'EUR')
      return currency.specialCharacter ?? currency.code
    }
    return ""
  },
}

function fillBeneficialData(template: string, beneficial: BeneficialEntity, t: (string) => string, language: LanguageType): string {
  const regex = /{(.*?)}/g;
  let data = { ...beneficial }
  for (const key in virtualbeneficialGetters) {
    data[key] = virtualbeneficialGetters[key](beneficial, t, language)
  }
  for (const key in data) {
    if (!data[key])
      data[key] = ""
  }
  try {
    return evalWithObject(template, data)

  } catch (error) {
    console.warn({
      template,
      beneficial,
      data
    });
    console.error(error)
    return ""

  }

  return template.replace(regex, (_, match) => {
    const key = match.trim() as keyof BeneficialCompanyEntity | keyof BeneficialPersonEntity | keyof BeneficialMinorEntity
    let value: any
    if (key in virtualbeneficialGetters) {
      value = virtualbeneficialGetters[key](beneficial, t, language)
    } else {
      value = getDeepValue(beneficial, key) || ''
    }
    if (
      value &&
      (key == 'cinDeliveryDate'
        || key == 'dateOfBirth'
        || key == 'createdAt'
        || key == 'updatedAt'
        || key == 'minorDateOfBirth'
        || key == 'minorOrderDate')
    ) {
      value = moment(new Date(value)).format(language == 'ar' ? "YYYY/MM/DD" : "DD/MM/YYYY")
    }
    return value
  })
}
export function getBeneficialDescritption(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType): string {
  const translations = require(`../../templates/${companyCode}/${language}.json`)

  const descriptionTemplate = tFromTranslations(`beneficialDescription.${beneficial.type}`, translations) || ''
  const description = fillBeneficialData(descriptionTemplate, beneficial, t, language)
  return description

}