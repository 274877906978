import React, { useState, useEffect } from 'react'
import AddparamForm from './AddparamForm'
import "./addParamDialog.scss"
import { useTranslation } from '../../../../contexts/TranslationProvider'

function AddParamDialog({ chars, insertMention, index, search, handleClose }) {
    const [insertNew, setInsertNew] = useState(false)
    const [selectedChar, setSelectedChar] = useState(null)
    const [currentSearch, setSearch] = useState('')
    const [filteredChars, setFilteredChars] = useState(chars)
    const { t, language } = useTranslation()
    const translationPath = "pages.contractTemplateParam.addParamForm";

    useEffect(() => {
        if (language == "ar") {
            setFilteredChars(chars.filter(char => char.label ? char.label.toLowerCase().includes(currentSearch.toLowerCase()) : false))
        } else {
            setFilteredChars(chars.filter(char => char.label ? char.label.toLowerCase().includes(search.toLowerCase()) : false))
        }

    }, [search, chars, currentSearch, language])

    const handleClick = (char) => {
        if (char.type === 'beneficial' || char.type === 'beneficial[]') {
            setSelectedChar(char)
            setInsertNew(true)
            return;
        }
        insertMention(char)
    }

    const handleAdd = () => {
        setInsertNew(true)
        setSelectedChar(null)
    }
    return (
        <div style={{ position: "relative", paddingTop: "15px" }}>
            <span className='close-dialog' onClick={() => handleClose()}>X</span>
            {!insertNew ?
                <>
                    <div className='dialog-param-list'>
                        {filteredChars.length > 0 && filteredChars.map((char, i) => (
                            <div
                                key={char.name}
                                onClick={() => handleClick(char)}
                                style={{
                                    background: i === index ? '#eee3fd' : 'transparent',
                                    color: i === index ? '#2f14e5' : '#212529',
                                }}
                                className='dialog-element'
                            >
                                {char.label}
                            </div>
                        ))}
                    </div>
                    <div style={{ display: language !== "ar" ? "none" : "block" }}>
                        <input type='text' placeholder='...' className='dialog-search' value={currentSearch} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                    <div className='dialog-add-element' onClick={() => handleAdd()}>
                        {t(translationPath + ".add")}
                    </div>
                </> : <>
                    <AddparamForm search={search} selected={selectedChar} params={chars} setInsertNew={setInsertNew} insertMention={insertMention} handleClose={handleClose} />
                </>
            }
        </div>
    )
}

export default AddParamDialog