import { useRef, useState } from "react";
import FileInput from "../../../common/FileInput";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import useApiClientWithLoading from "../../../../services/api/ApiClient";
import { toast } from "react-toastify";
import Loading from "../../../common/Loading";
import { FormTemplateClient } from "../../../../services/api/FormTemplateClient";
import { useNavigate } from "react-router-dom";
import PendingAi from "../../../common/newDesign/PendingAi";

function ImportCSV({ onClose, loading, setLoading }) {
  const [file, setFile] = useState<File>(null);
  const { t, language } = useTranslation();
  const apiClient = useApiClientWithLoading(setLoading);
  const formTemplateClient = new FormTemplateClient(apiClient)
  const navigate = useNavigate();

  const handleSave = async () => {
    if (file) {

      setLoading(true)
      try {
        const res=await formTemplateClient.import(file)
        toast.success(t("modals.importFormTemplate.messages.success"));
        navigate(`/edition-form-template/${res?.rows[0]?.id}`);
      } catch (error) {
        toast.error(t("modals.importFormTemplate.messages.error"));
      } finally {
        setLoading(false)
        onClose()
      }
    }
  };

  return (
    <div>
      {loading ? (
          <PendingAi height="100%" />
      ) : (
        <div className="modal-body" style={{ minHeight: "200px" }}>
          <div className="form-floating mb-3">
            <FileInput
              onChange={(file) => setFile(file)}
              value={file}
              borderClassName={file ? "green-border" : "grey-border"}
            />
          </div>

          <div className="modal-footer">
            {
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSave}
              >
                {t("modals.importFormTemplate.import")}
              </button>
            }
          </div>
        </div>
      )}
    </div>
  );
}

export default ImportCSV;
