import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import "./formTemplateParameterInput.scss";
import BeneficialRender from "./BeneficialRender";
import TableRender from "./TableRender";
import BooleanRender from "./BooleanRender";
import FileRender from "./FileRender";
import ListRender from "./ListRender";
import EnumRender from "./EnumRender";
import DefaultRender from "./DefaultRender";

function FormTemplateParameterInput({
  page,
  param,
  isEditing,
}) {
  const { onParamChanged} = useContext(EditContractTemplateContext);
  const [localValue, setLocalValue] = useState(param.label);
  const [localNameValue, setLocalNameValue] = useState(param.name);
  const [errors, setErrors] = useState([])
  const { t, language } = useTranslation();
  const translationPathErrors =t("pages.contractTemplateParam.addParamForm");
  
  useEffect(() => {
    setLocalValue(param.label);
    setLocalNameValue(param.name)
  }, [param.label, param.name]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setLocalValue(value);
    onParamChanged(page.id, { ...param, label: value });
  };
  
  const handleInputNameChange = (e) => {
    const value = e.target.value;
    const newErrors = [];

    if (!/^[a-z_][a-z0-9_]*$/i.test(value)) {
      newErrors.push({ where: "name", why: translationPathErrors.errors.nameWrong });
    }

    const existingParam = page.params.find(p => p.name === value);
    if (existingParam) {
      newErrors.push({ where: "name", why: translationPathErrors.errors.nameExists });
    }

    setLocalNameValue(value);
    setErrors(newErrors);

    if (newErrors.length === 0) {
      onParamChanged(page.id, { ...param }, value);
    }
  };





  switch (param.type) {
    case "beneficial":
      return (
        <BeneficialRender
        value={localValue}
        onChange={handleInputChange}
        onChangeName={handleInputNameChange}
        param={param}
        errors={errors}
        page={page}
        valueName={localNameValue}

        isEditing={isEditing}
        />
      );
    case "table":
      return (
        <TableRender
        value={localValue}
        onChange={handleInputChange}
        onChangeName={handleInputNameChange}
        param={param}
        errors={errors}
        valueName={localNameValue}
        page={page}
        isEditing={isEditing}
        />
      );
    case "boolean":
      return (
        <BooleanRender
        value={localValue}
        onChange={handleInputChange}
        onChangeName={handleInputNameChange}
        valueName={localNameValue}
        errors={errors}

        param={param}
        page={page}
        isEditing={isEditing}
        />
      );
    case "file":
      return (
        <FileRender
        value={localValue}
        onChange={handleInputChange}
        onChangeName={handleInputNameChange}
        valueName={localNameValue}
        errors={errors}
        param={param}
        isEditing={isEditing}
        />
      );
    case "csv":
      return (
        <FileRender
        value={localValue}
        errors={errors}
        onChange={handleInputChange}
        onChangeName={handleInputNameChange}
        valueName={localNameValue}

        param={param}
        isEditing={isEditing}
        />
      );
    case "list":
      return (
        <ListRender
        value={localValue}
        onChange={handleInputChange}
        onChangeName={handleInputNameChange}
        valueName={localNameValue}
        errors={errors}
        param={param}
        page={page}
        isEditing={isEditing}
        />
      );
    case "enum":
      return (
        <>
        <EnumRender
        value={localValue}
        onChange={handleInputChange}
        errors={errors}
        onChangeName={handleInputNameChange}
        valueName={localNameValue}

        param={param}
        page={page}
        isEditing={isEditing}
        />
        </>
      );
    default:
      return (
       <DefaultRender
        value={localValue}
        valueName={localNameValue}
        onChange={handleInputChange}
        onChangeName={handleInputNameChange}
        param={param}
        errors={errors}
        isEditing={isEditing}
        />
      );
  }
}

export default FormTemplateParameterInput;