import React, { useState } from "react";
import Loading from "../../common/Loading";
import { useTranslation } from "../../../contexts/TranslationProvider";
import "./confirmDeletePopup.scss";

interface ConfirmDeleteTypeProps {
  onClose: () => void;
  msgDelete?: string;
  entityToDelete?: any; 
  onDelete?:()=>void
}

function ConfirmDeletePopup({
  onClose,
  msgDelete,
  entityToDelete,
  onDelete
}: ConfirmDeleteTypeProps) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  
  const handleClick=async()=>{
   onDelete()
   onClose()
  }

  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="contractz-lab" className="confirm-delete-popup">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t("modals.confirmDelete.title")}
                </h5>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body pb-3">
                  <div className="form-group">
                    <h1 className="question-modal-delete">
                    {msgDelete +" "+entityToDelete}
                    </h1>

                    <div className="modal-buttons">
                      <button
                        className="btn submit-delete"
                        onClick={handleClick}
                      >
                        {t("modals.confirmDelete.delete")}
                      </button>

                      <button
                        onClick={() => onClose()}
                        className="btn btn-secondary cancel-delete"
                        style={{
                          marginLeft: "20px",
                          backgroundColor: "gray",
                        }}
                      >
                        {t("modals.confirmDelete.cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmDeletePopup;
