import React, { useEffect, useState } from "react";
import "../commonmodal.scss";

import useApiClientWithLoading from "../../../services/api/ApiClient";
import Loading from "../../common/Loading";

import { useTranslation } from '../../../contexts/TranslationProvider';
import { BaseEntity, ModelClient } from "../../../services/ModelClient";
import CustomCombobox from "../../common/CustomCombobox";
interface EntityLookupProps<E extends BaseEntity> {
	onClose: () => void;
	onSave: (entity: E | null) => void;
	modelName: string;
	lookUpField: (keyof E) & string;
	value: E | null;
}

function genEntityLookupModal<E extends BaseEntity>() {
	const EntityLookupModal = ({ value, onClose, onSave, modelName, lookUpField }: EntityLookupProps<E>) => {
		const [loading, setLoading] = useState(false);
		const { t, language } = useTranslation();
  const translationPath = t("modals.exportToDocx");
		const [entity, setEntity] = useState<E | null>(value);
		const [openCombobox, setOpenCombobox] = useState<string | null>("");

		const [entities, setEntities] = useState<E[]>([]);

		const apiClient = useApiClientWithLoading();
		const modelClient = new ModelClient<E>(apiClient, "ContractDocxExport")

		useEffect(() => {
			modelClient.getAll().then(({ rows }) => {
				setEntities(rows)
			})
			return () => {
			}
		}, [])
		return (
			<>
				<div
					className="modal-backdrop"
				></div>
				<div id="contractz-lab">
					<div className="modal d-flex justify-content-center align-items-center">
						<div>
							<div className="modal-content" style={{ width: "960px" }}>
								<div className="modal-header">
									<h5 className="modal-title">  {modelName}</h5>
									<button
										type="button"
										className="btn-close"
										onClick={onClose}
										aria-label="Close"
										style={{ marginRight: language == "ar" && "88%" }}
									></button>
								</div>
								{loading ? (
									<Loading height="50vh" />
								) : (
									<div className="modal-body" style={{ paddingBottom: "5%" }}>
										<div className="form-group validate">
											<div className="form-floating mb-3">
												<CustomCombobox
													canDeselect
													isValid={true}
													label={lookUpField}
													onDropdownOpen={() => { }}
													options={entities ?? []}
													value={entity}
													onChange={(e) => setEntity(e as any)}
													optionValue={(option) => option}
													optionDisplay={(option) => option?.["name"] ?? ""}
													isOpen={openCombobox === "name"}
													setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "name" : null)}
												/>
											</div>
										</div>
									</div>
								)}
								<div className="modal-footer">
									<button
										type="submit"
										className="btn btn-primary submit-validate-contract-btn"
										onClick={() => {
											onSave(entity)
											onClose()
										}}
									>
								{translationPath.select}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	return EntityLookupModal
}


export default genEntityLookupModal;
