import "./myContracts.scss";
import { BsSearch } from "react-icons/bs";
import Contractslist from "./ContractsList";
import { useCallback, useEffect, useState } from "react";
import useApiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import { useTranslation } from '../../contexts/TranslationProvider';
import { ContractEntity, ContractAIEntity, ContractTemplateEntity, DocumentEntity } from "../../domain/entities";
import { ContractClient } from "../../services/api/ContractClient";
import { ContractAIClient } from "../../services/api/ContractAIClient";
import CustomCombobox from "../../components/common/CustomCombobox";
import { DocumentClient } from "../../services/api/DocumentClient";
import { set } from "react-hook-form";
const aiIcon = require('../../assets/new-design-icons/ai.png')

function MyContracts() {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState<(ContractEntity | ContractAIEntity | DocumentEntity | DocumentEntity)[]>([]);
  const [filteredContracts, setFilteredContracts] = useState<(ContractEntity | ContractAIEntity | DocumentEntity)[]>([]);
  const [resultContracts, setResultContracts] = useState<(ContractEntity | ContractAIEntity | DocumentEntity)[]>([]);
  const [typeFilter, setTypeFilter] = useState(null);
  const types = [
    {
      name: "Template",
      id: "template"
    },
    {
      name: "AI",
      id: "AI"
    }
  ]
  const [searchTerm, setSearchTerm] = useState("");
  const [templateFilter, setTemplateFilter] = useState(null);
  const { t , language} = useTranslation();
  const apiClient = useApiClientWithLoading();
  const contractClient = new ContractClient(apiClient)
  const contractAIClient = new ContractAIClient(apiClient)
  const documentClient = new DocumentClient(apiClient)
  const translationPath ="pages.contracts."
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
        const allContracts = await contractClient.getAll();
        //const allContractAI = await contractAIClient.getAll();
        const allDocs = await documentClient.getAll("full");
        const allSummaries = allDocs.rows.filter(doc => doc.summary && doc.summary.id)
        const result = [...allContracts.rows.map(e => ({...e, type: "Contract"})), ...allSummaries.map(e => ({...e, type: "Summary"}))].sort(
          (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        )
        // const templatesSet: ContractTemplateEntity[] = []
        // allContracts.rows.forEach((contract) => {
        //   if (contract.template) {
        //     if(!templatesSet.find(template => template.id === contract.template.id))
        //       templatesSet.push(contract.template)
        //   }
        // });
        // templatesSet.push({name: t(translationPath + "AI"), id: -1})
        // setExistingTemplates(templatesSet)
        // console.log(templatesSet)
        setTemplateFilter(null)
        setContracts(result);
        setFilteredContracts(result);
        setResultContracts(result);
        setLoading(false); // Set loading to false after contracts are fetched
      } catch (error) {
        console.error("Error fetching contracts: ", error);
        setLoading(false); // Also set loading to false in case of error
      }
    }, [language])
    useEffect(() => {
      fetchData();
    }, [fetchData]);

  useEffect(() => {
    setResultContracts(
      filteredContracts.filter((contract) =>
        (contract as any).name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  const handleTemplateFilterChange = (choice) => {
    if(!choice){
      setFilteredContracts(contracts)
      setTemplateFilter(null)
    } else if(choice.id == "AI") {
      setFilteredContracts(contracts.filter(contract => (contract as any).type === "Summary"))
      setTemplateFilter(choice)
    }else {
      setFilteredContracts(contracts.filter(contract => (contract as any).type === "Contract"))
      setTemplateFilter(choice)
    }
  }

  useEffect(() => {
    setResultContracts(filteredContracts)
  }, [filteredContracts]);

  return (
  <div  style={{width: "100%", flex: 1}} className="w-100 contracts-page">
      <section id="contracts" className="pt-2 px-2">
        <span className="current-pathname">{t("pages.contracts.title")}</span>
        <div style={{padding: "20px"}} className="search-zone">
          <div className="ressources-filters">
            <div className="filter-item">
              <CustomCombobox
                canDeselect
                label=""
                onDropdownOpen={() => { }}
                options={types}
                value={templateFilter}
                onChange={handleTemplateFilterChange}
                optionDisplay={(option) => (<>
                  {option ? <div className="filter-type-item">
                  {option.id == "AI" && <img src={aiIcon} alt="icon" style={{ width: "35px", height: "35px" }} />}
                    <span>
                      {t(translationPath + "filters." + option.id)}
                    </span>
                  </div> :
                    <span>
                      {t(translationPath + "source")}
                    </span>
                  }
                </>)}
                fullWidth={true}
                isOpen={openCombobox === "source"}
                setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "source" : null)}
              />
            </div>
          </div>
          <div className="search-section">
            <div className="search w-75">
              <BsSearch className="search-icon" />
              <input
                type="text"
                placeholder={t("pages.ressources.search")}
                className="search-input"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="ressources-list">
          {loading ? (
            <div style={{width: "83vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Loading height="75vh" />
            </div>
          ) : (
            <Contractslist
              contracts={resultContracts}
              reloadContracts={fetchData}
            />
          )}
        </div>
      </section>
    </div>
  );
}

export default MyContracts;
