import React, { useState, useRef, useContext, useEffect } from "react";
import "./clauseSelect.scss";
import ComparisonClause from "./ComparisonClause";
import { useTranslation } from "../../../contexts/TranslationProvider";
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
import { ContractTemplateClient } from "../../../services/api/ContractTemplateClient";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { toast } from "react-toastify";
import ImportConfirmModal from "../ImportConfirmModal";
import { normalizeName } from "../../../utils/string";
import { getName } from "../../../helpers/Translation";

function ClauseSelectModal({
  onClose,
  addNewClause,
}: {
  onClose: () => void;
  addNewClause: any;
}) {
  const modalComparableRef = useRef(null);
  const [currentClause, setCurrentClause] = useState(null);
  const [searchClause, setSearchClause] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredClauses, setFilteredClauses] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const lookup = require("../../../assets/lookup.png");
  const translationPath = "modals.clauseSelect.";
  const { t, language } = useTranslation();
  const { contractTemplate, insertImportedClause } = useContext(
    EditContractTemplateContext
  );
  const apiClient = useApiClientWithLoading();
  const contractTemplateClient = new ContractTemplateClient(apiClient);
  const searchRef = useRef(null);
  const handleInputChange = (event) => {
    setSearchClause(event.target.value);
    setShowDropdown(true);
  };
  const handleDropdownSelect = (item) => {
    setCurrentClause(item);
    setSearchClause(item.name);
    setFilteredClauses([]);
    setShowDropdown(false);
  };
  useEffect(() => {
    if (!searchClause) return;
    const searchClauses = async (name: string) => {
      try {
        setLoading(true);
        const result = await contractTemplateClient.searchClauses(searchClause);
        const { rows: clauses } = result;
        setFilteredClauses(
          clauses.filter((clause) => clause.template.id !== contractTemplate.id)
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error searching clauses:", error);
        toast.error(t(translationPath + "toastsSearch.error"));
      }
    };
    searchClauses(searchClause);
  }, [searchClause]);

  const handleConfirm = async () => {
    try {
      const nameExists = contractTemplate.clauses.find(
        (clause) =>
          normalizeName(clause.name) === normalizeName(currentClause.name)
      );
      if (nameExists) {
        setConfirmModal(true);
      } else {
        try {
          const newTemplate = await insertImportedClause(
            addNewClause,
            currentClause.id,
            currentClause.name
          );
          toast.success(t(translationPath + "toastsImport.success"));
        } catch (err) {
          console.error(err);
          toast.error(t(translationPath + "toastsImport.error"));
        }
        onClose();
      }
    } catch (err) {
      console.error("Error inserting clause:", err);
      toast.error(t(translationPath + "toastsInsert.error"));
    }
  };

  // handle click outside of searchRef
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  return (
    <>
      <>
        <div className="modal-backdrop"></div>
        {confirmModal && (
          <ImportConfirmModal
            onClose={() => setConfirmModal(false)}
            currentClause={currentClause}
            closeParentModal={onClose}
            insertIdx={addNewClause}
          />
        )}
        <div id="contractz-lab">
          <div className="modal d-flex justify-content-center align-items-center">
            <div className="clause-comparison-modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t(translationPath + "title")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language == "ar" && "90%" }}
                ></button>
              </div>
              <div className="body-comparison">
                <div
                  className="clause-comparison-choice"
                  ref={modalComparableRef}
                  style={{ position: "relative" }}
                >
                  <div
                    ref={searchRef}
                    style={{ marginBottom: "10px" }}
                    className="project-beneficial-name"
                  >
                    <input
                      type="text"
                      className=""
                      value={searchClause}
                      onChange={(event) => handleInputChange(event)}
                      style={{ width: "80vw" }}
                    />
                    <img
                      src={lookup}
                      alt="#"
                      className={`lookup-img ${
                        language == "ar" && "lookup-img-arabic"
                      }`}
                    />
                    {currentClause && (
                      <span
                        onClick={() => {
                          setCurrentClause(null);
                          setSearchClause("");
                          setShowDropdown(false);
                        }}
                        className={`x-img ${
                          language == "ar" && "x-img-arabic"
                        }`}
                      >
                        X
                      </span>
                    )}

                    {showDropdown && (
                      <div className="list-beneficial-project">
                        {filteredClauses && filteredClauses.length > 0 ? (
                          filteredClauses.map((item, index) => (
                            <div
                              className="item-beneficial-project"
                              key={index}
                              onClick={() => handleDropdownSelect(item)}
                            >
                              <span>{item.name}</span>
                              <div className="d-flex flex-col">
                                {item?.template?.level1?.name && (
                                  <span className="level-types-template">
                                    {getName(item?.template?.level1, language) +
                                      (item?.template?.level2
                                        ? "/" +
                                          getName(
                                            item?.template?.level2,
                                            language
                                          )
                                        : "") +
                                      (item?.template?.level3
                                        ? "/" +
                                          getName(
                                            item?.template?.level3,
                                            language
                                          )
                                        : "")}
                                  </span>
                                )}
                                <span className="item-template-name">
                                  {item.template?.name}
                                </span>
                              </div>
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="clause-comparison-container">
                  {currentClause ? (
                    <ComparisonClause clause={currentClause} />
                  ) : (
                    <span>{t(translationPath + "noClause")}</span>
                  )}
                </div>
              </div>
              <button
                disabled={!currentClause}
                className={`confirm-button ${
                  !currentClause && "disabled-confirm-button"
                }`}
                onClick={handleConfirm}
              >
                {t(translationPath + "import")}
              </button>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default ClauseSelectModal;
