import EventManager from "../../../../services/EventManager";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import '../buttons.scss'
function ExportFormButton() {
    const { t } = useTranslation();
    const handleExportCSV = async () => {
        EventManager.invoke('ExportCSV', true)
    };
    return (
        <button className="navbar-button" onClick={handleExportCSV}>
            {t("navbar.exportCSV")}
        </button>
    );
}

export default ExportFormButton;
