import { getRenderSegments } from "../helpers/segmentation";
import { ClauseEntity, ContractEntity, ContractTemplateEntity, SubClauseEntity } from "./entities";
import { RenderSegments, SegmentedTextType } from "./types/ClauseParams";


export type ContractExportText = {
	name: string;
	preambule?: {
		name: string;
		text: string;
		subClauses: ({
			name: string;
			text: string;
		})[];
	};
	clauses: ({
		name: string;
		text: string;
		subClauses: ({
			name: string;
			text: string;
		})[];
	})[];
}

export function getContractExportData(contract: ContractEntity, t: (str: string) => string): ContractExportText {
	const template: ContractTemplateEntity = contract.template
	let textExport: ContractExportText = {
		name: contract.name!,
		clauses: template?.clauses?.map((clause) => {
			const renderSegments = getRenderSegments(clause.segmentation?.segmentedText!, contract.paramValues, contract.fileNames, contract.beneficialsMap, clause.segmentation?.segmentedParams!, t, 'fr')
			const text = renderSegmentsToDocXml(contract, clause, renderSegments)
			return {
				name: clause.name!,
				text: text,
				subClauses: clause.subClauses?.map((subClause) => {
					const renderSegments = getRenderSegments(subClause.segmentation?.segmentedText!, contract.paramValues, contract.fileNames, contract.beneficialsMap, subClause.segmentation?.segmentedParams!, t, 'fr')
					const text = renderSegmentsToDocXml(contract, subClause, renderSegments)
					return {
						name: subClause.name!,
						text: text,
					}
				}) ?? []
			}
		}) ?? []
	}
	return textExport
}

export function renderSegmentsToDocXml(contract: ContractEntity, clause: ClauseEntity | SubClauseEntity, renderSegments: RenderSegments) {
	{
		const text = renderSegments?.map((segment, idx) => {
			const { id, value, type } = segment
			const text = contract.segmentsOverrides?.[id] ?? value

			const sanitizedText = text?.replaceAll(/\[\s*\]/g, " ")
			switch (type) {
				case SegmentedTextType.STATIC:
					return `<w:rPr><w:b w:val="0"/></w:rPr><w:t xml:space="preserve">${sanitizedText || ""}</w:t>`
				case SegmentedTextType.COMMENT:
					return ""
				case SegmentedTextType.PARAM:
					const paramPath = value.split(".");
					const paramLabel = clause.segmentation?.segmentedParams.find((param) => param.name == segment.paramName)?.label;
					return `<w:rPr><w:b/></w:rPr><w:t xml:space="preserve">${paramLabel ?? value}${paramPath[1] ? `(${paramPath[1]})` : ""}</w:t>`
				case SegmentedTextType.PARAM_VALUE:
					return `<w:rPr><w:b/></w:rPr><w:t xml:space="preserve">${sanitizedText || ""}</w:t>`
				case SegmentedTextType.PARAM_TABLE_VALUE:
					return ""
				case SegmentedTextType.PARAM_COMMENT_VALUE:
					return ""
				default:
					return ""
			}
		}).join("")
		return text
	}
}