import { Descendant } from 'slate'
import { SegmentedTextType } from '../../../../domain/types/ClauseParams'
import { useEffect } from "react";

export const buildBlocks = (segments, params) => {
  const newBlocks: Descendant[] = []
  let children = []
  segments.forEach((segment, index) => {
    if (segment.type === SegmentedTextType.STATIC) {
      if (segment.isHighlighted) {
        children.push(
          {
            text: segment.value,
            id: segment.id,
            bold: true,
          }
        )
      } else {
        children.push({ text: segment.value, id: segment.id })
      }
    }
    if (segment.type === SegmentedTextType.PARAM) {
      // insert as mention
      const param = params.find(p => p.name === segment.paramName)
      const label = param?.label || ''
      const fullLabel = (param.type === 'beneficial' || param.type == 'beneficial[]') ? `${label}` + ` ${segment.value.split('.')[1] && `(${segment.value.split('.')[1]})`} ` : label
      children.push({
        type: 'mention',
        character: fullLabel,
        id: segment.id,
        children: [{ text: segment.paramName }]
      })
    }
    if (segment.type === SegmentedTextType.COMMENT) {
      children.push({
        text: segment.value,
        id: segment.id,
        italic: true,
      })
    }
  })
  newBlocks.push({
    type: 'paragraph',
    children
  })
  return newBlocks
}
export function useOutsideAlerter(ref, func, currentSegment) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, func, currentSegment]);
}
