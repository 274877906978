import React, { useContext, useState } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import "./RessourcesProject.scss";
import form from "../../../assets/new-design-icons/Form.svg";
import contract from "../../../assets/new-design-icons/Contract.svg";
import { FiMoreHorizontal } from "react-icons/fi";
import ProjectTemplateCardActionsModal from "../../../components/modals/ProjectTemplateCardActionsModal";
import OptionsRessourceProject from "./OptionsRessourceProject";
import { getName } from "../../../helpers/Translation";
import { formatDate } from "../../../helpers/helper";
import EditProjectTemplateContext from "../../../contexts/ProjectTemplateContext";
import { ProjectTemplateRequirementEntity } from "../../../domain/entities";
import ProjectTemplateGroups from "../../ContractTemplate/components/ProjectTemplateGroups";
const docIcon = require("../../../assets/new-design-icons/doc.png");

function RessourcesProject({ ressources, setRessources, interfaceTemplate = false }: {
  ressources?: ProjectTemplateRequirementEntity[],
  setRessources?: (resources: any) => void,
  interfaceTemplate?: boolean
}) {
  const { t, language } = useTranslation();
  const [openPopup, setOpenPopup] = useState<string | null>(null);
  const [changeGroups, setChangeGroups] = useState<Record<string, boolean>>({});
  const translationPathEdition = "pages.EditProjectTemplate.";
  const {
    projectTemplate,
    onSelectGroupInRequirement,
    projectTemplateRequirements,
    setProjectTemplate,
    setProjectTemplateRequirements,
    groups
  } = useContext(EditProjectTemplateContext);
  const [openEditRessource, setOpenEdit] = useState<ProjectTemplateRequirementEntity | null>(null);
  const translationPath = "pages.ressources.";
  const handleDelete = (name: string) => {
    const deletedRequirement = ressources?.find(
      (ressource) => ressource.name === name
    ) || projectTemplateRequirements?.find((ressource) => ressource.name === name);

    if (deletedRequirement) {
      setRessources
        ? setRessources((prevRessources) =>
          prevRessources.filter((ressource) => ressource.name !== name)
        )
        : setProjectTemplateRequirements((prevRessources) =>
          prevRessources.filter((ressource) => ressource.name !== name)
        );
      setProjectTemplate((prevProjectTemplate) => ({
        ...prevProjectTemplate,
      }));
    }
  };



  const togglePopup = (e: React.MouseEvent<HTMLButtonElement>, name: string) => {
    e.stopPropagation();
    setOpenPopup(openPopup === name ? null : name);
  };

  const toggleEyeIcon = (name: string) => {
    setChangeGroups((prevState) => ({
      [name]: !prevState[name],
    }));
  };

  const formIcon = form.toString();
  const contractIcon = contract.toString();

  const getIcon = (types: number[]) => {
    switch (types[0]) {
      case 8:
        return <img src={formIcon} alt="Form icon" style={{ width: "30px", height: "30px" }} />;
      case 2:
        return <img src={contractIcon} alt="Contract icon" style={{ width: "30px", height: "30px" }} />;
      case 1:
        return <img src={docIcon} alt="Document icon" style={{ width: "30px", height: "30px" }} />;
      default:
        return null;
    }
  };

  const renderResources = (resources: ProjectTemplateRequirementEntity[]) => {
    return resources?.map((ressource) => (
      <>
        <div className="admin-ressource-card-container" style={{ position: "relative" }} key={ressource.index}>
          <div className="header">
            <div className="title">
              <div className="icon">{getIcon(ressource.types)}</div>
              <div className="name">{ressource.name}</div>
            </div>
            <div className="d-flex">
              <div className="actions">
                <button className="plus-icon" onClick={(e) => togglePopup(e, ressource.name)}>
                  <FiMoreHorizontal size={20} />
                </button>
                {openPopup === ressource.name && (
                  <div className="popup">
                    <ProjectTemplateCardActionsModal
                      onEdit={() => setOpenEdit(ressource)}
                      onDelete={() => handleDelete(ressource.name)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="body">
            <div className="body-section">
              <span>
                <span className="label">
                  {t("pages.ressources.cardLabels.type")}
                </span>
                <span className="value">
                  {ressource?.level1?.name && ressource.types[0] !== 1 && (
                    <span>
                      {getName(ressource.level1, language) +
                        (ressource.level2 ? "/" + getName(ressource.level2, language) : "") +
                        (ressource.level3 ? "/" + getName(ressource.level3, language) : "")}
                    </span>
                  )}
                  {ressource?.types[0] === 1 && (
                    <span>{ressource.name}</span>
                  )}
                </span>
              </span>
            </div>
            {ressource.createdAt && (
              <div className="timestamp mb-3">
                <div>
                  <span className="value">
                    {t(translationPath + "cardLabels.updatedAt")}
                  </span>
                  <span className="value">
                    {formatDate(ressource.updatedAt as any as string)}
                  </span>
                </div>
                <div>
                  <span className="value">
                    {t(translationPath + "cardLabels.createdAt")}
                  </span>
                  <span className="value">
                    {formatDate(ressource.createdAt as any as string)}
                  </span>
                </div>
              </div>
            )}
            <div className="body-section">
              <p className="document-required">
                {ressource.isOptional ? t(translationPathEdition + "requirement.notRequired") : t(translationPathEdition + "requirement.required")}
              </p>
            </div>
          </div>
        </div>
      </>
    ));
  };

  return (
    <>
      {(ressources && ressources.length > 0) || projectTemplateRequirements?.length > 0 ? (
        <div className={`${interfaceTemplate ? "projectTemplateRessources" : "container-ressources-project"}`}>
          {ressources ? renderResources(ressources) : renderResources(projectTemplateRequirements)}
        </div>
      ) : null}
      {openEditRessource && (
        <OptionsRessourceProject
          selectedType={
            openEditRessource.types?.[0] === 1
              ? "document"
              : openEditRessource.types?.[0] === 2
                ? "contract"
                : "form"
          }
          typeOfCreation={
            openEditRessource.types?.[0] === 1
              ? "document"
              : openEditRessource.types?.[0] === 2
                ? "contract"
                : "form"
          }
          setRessources={setRessources ? setRessources : setProjectTemplateRequirements}
          onClose={() => setOpenEdit(null)}
          ressource={openEditRessource}
        />
      )}
    </>
  );
}

export default RessourcesProject;
