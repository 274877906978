import Mention from './Mention'

const Element = props => {
    const { attributes, children, element } = props
    switch (element.type) {
      case 'mention':
        return <Mention {...props} />
      default:
        return <>{children}</>
    }
  }

export default Element