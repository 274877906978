import "./formPageInput.scss";
import { useContext, useEffect, useMemo, useState } from "react";
import React from "react";
import { useCollapse } from "react-collapsed";
import { FormTemplatePageEntity } from "../../../domain/entities";
import EditFormContext from "../../../contexts/EditFormContext";
import FormParameterInput from "./FormParameterInput";
import { getAllRenderedParamNames, getAllRenderedParams, orderRenderedParams } from "../../../domain/FormTemplate";
import EventManager from "../../../services/EventManager";
import { serializeParamValuesForm } from "../../../hooks/serializeParamValues";
import { saveWorkbook } from "../../../utils/csv";
function FormPageInput({ page }: {
  page: FormTemplatePageEntity;

}) {
  const {
    paramValues, setParamValues,
    selected, setSelected,
    beneficialsMap,
    fileNames,
    template,
    name
  } = useContext(EditFormContext);
  const isSelected = selected.pageId === page.id;
  const [isExpanded, setExpanded] = useState(isSelected);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [showBenificialModel, setShowBenificialModel] = useState(false);
  const [triggeredParam, setTriggeredParam] = useState<string | null>(null);


  useEffect(() => {
    setExpanded(isSelected)
  }, [isSelected]);

  useEffect(() => {
    if (triggeredParam) {
      setTimeout(() => {
        setTriggeredParam(null);
        console.log("setTOnull")
      }, 1000);
    }
  }, [triggeredParam]);

  function handleInputChange(value: any, paramName: string) {
    setParamValues({
      ...paramValues,
      [paramName]: value
    })
    setTriggeredParam(paramName);
  }
  const rendredParams = useMemo(() => {
    const params = getAllRenderedParams(page.params ?? [], paramValues)
    return params.filter(p => !p.condition || !p.condition[0])
  }
    , [page.params, paramValues])

  useMemo(() => console.log({ rendredParams })
    , [JSON.stringify(rendredParams)])
  const saveFormAsCSV = async () => {
    try {
      const workbook = serializeParamValuesForm(paramValues, template, fileNames, beneficialsMap)
      saveWorkbook(workbook, `${name}.xlsx`);

    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    EventManager.subscribe('ExportCSV', saveFormAsCSV)
    return () => {
      EventManager.unsubscribe('ExportCSV', saveFormAsCSV)
    }
  }, [])

  return (
    <div
      key={page.id}
      id="clause-group"
      className="formPageInput-edition"
    >
      <div className="d-flex gap-2">
        <div className="edit-contract-clause-input-header-form" style={{ padding: "0rem", marginTop: "-10px", marginBottom: "10px" }}>
          <h5 style={{ color: "#2B2E31" }}>{page.name}</h5>
        </div>
      </div>
      <div>
        <div className="wrapper-form-input">
          {rendredParams.map((param) => {
            return (
              <div key={`${page.id}-${param.name}`} className={`form-item-input-edition ${param.type === "beneficial" && "w-100"}`}>
                <div className={`px-2 d-flex justify-content-center w-100`}>
                  <FormParameterInput
                    page={page}
                    param={param}
                    setShowBeneficial={setShowBenificialModel}
                    handleInputChange={handleInputChange}
                    triggeredParam={triggeredParam}
                  />

                </div>{" "}
              </div>
            );
          })}
        </div>
        {/* Render the optional subclause toggle buttons at the end */}
      </div>
    </div>
  );
}
export default FormPageInput;
