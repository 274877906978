import { createContext } from "react";
import { FormTemplateEntity, FormTemplatePageEntity, GroupEntity } from "../domain/entities";
import { FormParam } from "../domain/types/FormParams";
import { EnumOption, ListOption, TableLigne } from "../domain/types/ClauseParams";


export interface EditFormTemplateContextType {
	formTemplate: FormTemplateEntity;
	setFormTemplate: React.Dispatch<React.SetStateAction<FormTemplateEntity>>;
	addPage: (index: string, name: string) => void;
	onAddParam: (formPageId: FormTemplatePageEntity['id'], param: FormParam) => void;
	onParamChanged: (formPageId: FormTemplatePageEntity['id'], param: FormParam, newValue?: string) => void;
	onDeleteParam: (formPageId: FormTemplatePageEntity['id'], param: FormParam) => void;
	onOptionChanged: (formPageId: FormTemplatePageEntity['id'], param: FormParam, option: any, index: number) => void;
	onOptionDelete: (formPageId: FormTemplatePageEntity['id'], param: FormParam, index: number) => void;
	onOptionAdd: (formPageId: FormTemplatePageEntity['id'], param: FormParam, option: EnumOption | ListOption | TableLigne | boolean) => void;
	paramValues: Record<string, any>[] | null;
	setParamValues: React.Dispatch<React.SetStateAction<Record<string, any>[] | null>>;
	onValueChange: (formPageId: FormTemplatePageEntity['id'], param: FormParam, value: any) => void;
	onResetValue: (formPageId: FormTemplatePageEntity['id'], param: FormParam) => void;
	onSetNewOrder: (formPageId: FormTemplatePageEntity['id'], newOrder: { name: string, index: number }[]) => void;
	onPageRename: (formPageId: FormTemplatePageEntity['id'], newName: string) => void;
	onTemplateRename: (newName: string) => void;
	onPageDelete: (formPageIndex: FormTemplatePageEntity['index']) => void;
	onSelectGroupInFormPage: (code: string, group: GroupEntity[]) => void;
	groups: GroupEntity[];
	setTemplateGroups: (group: GroupEntity, add: boolean) => void;
}

const EditContractTemplateContext = createContext<EditFormTemplateContextType>({

} as any);

export default EditContractTemplateContext;
