import React, { useState, useEffect, useContext } from 'react';
import './generateAIportal.scss';
import { useTranslation } from '../../../contexts/TranslationProvider';
import { toast } from 'react-toastify';
import { getName } from '../../../helpers/Translation';
import { ContractTemplateEntity, ClauseEntity } from '../../../domain/entities';
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";

const ContractTemplateGroups = ({ clauseGroup, groups, close, onSelectGroup, clauseIndex }: {
  onSelectGroup: (index: string, groups: ContractTemplateEntity['groups']) => void;
  close: () => void;
  clauseIndex: ClauseEntity['index'];
  groups: ContractTemplateEntity['groups'];
  clauseGroup: ContractTemplateEntity['groups'];

}) => {
  const { groups: originalGroups } = useContext(EditContractTemplateContext);
  const [groupsSelected, setGroupsSelected] = useState<ContractTemplateEntity['groups']>([]);
  const { t, language } = useTranslation();
  const translationPath = "pages.contractTemplateParam.addGroups";

  const handleChange = (e, groupSelected) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const newSelectedGroup = [...groupsSelected, groupSelected];
      setGroupsSelected(newSelectedGroup);
      onSelectGroup(clauseIndex, newSelectedGroup);
    }else{
      const newSelectedGroup = groupsSelected.filter(group => group.id !== groupSelected.id);
      setGroupsSelected(newSelectedGroup);
      onSelectGroup(clauseIndex, newSelectedGroup);
    }
  };


  useEffect(() => {
    if (clauseGroup) {
      const isConditionMet = clauseGroup.some(group =>
        group.Group_ContractTemplate?.clauseCodes?.some(code => code === clauseIndex)
      );
      if (isConditionMet) {
        const newSelectedGroup = clauseGroup.filter(group =>
          group.Group_ContractTemplate?.clauseCodes?.some(code => code === clauseIndex)
        );
        setGroupsSelected(newSelectedGroup);
      } else {
        setGroupsSelected([]);
      }
    } else {
      setGroupsSelected([]);
    }
  }, [clauseIndex]);

  return (
    <div>
      <div className="generate-ai-portal" style={{ marginTop: "" }}>
        <div className="generate-ai-portal-header">
          <span className='close-dialog' onClick={() => close()}>X</span>
        </div>
        <div className="generate-ai-portal-content">
          {groups.map((group, index) => {
            const isChecked = groupsSelected.some(selectedGroup =>
              selectedGroup.id === group.id &&
              selectedGroup.Group_ContractTemplate?.clauseCodes?.some(code => code === clauseIndex)
            );
            const originalGrp = originalGroups.find(g => g.id === group.id);
            return (
              <div key={index} className="generate-ai-portal-content-checkbox">
                <input
                  type="checkbox"
                  id={String(group.id)}
                  name={group.name}
                  checked={isChecked}
                  onChange={(e) => handleChange(e, group)}
                />
                <label className='generate-ai-portal-label' htmlFor={String(group.id)}>{getName(originalGrp, language)}</label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ContractTemplateGroups;
