import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
} from "react";
import { AiFillDelete, AiOutlineUpload } from "react-icons/ai";
const FileInputAdvanced = forwardRef<any, any>(({ onChange, value, label, ...props }: {

  onChange: (file: Buffer & { name: string; }) => void;
  value: Buffer & { name: string; };
  label?: string;
} & React.HTMLAttributes<HTMLDivElement>, ref) => {

  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState(value?.name ?? "");

  useEffect(() => {
    setFileName(value?.name ?? "");
  }, [value]);

  const triggerFileInput = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  useImperativeHandle(ref, () => ({
    triggerFileInput,
  }));

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onChange(file);
    }
  };

  const deleteFile = () => {
    onChange(null);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        border: "dashed 2px #ccc",
        padding: "0.5rem",
        cursor: "pointer",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
      }}
      {...props}
    >
      <div className="d-flex ms-1">
        {fileName && (
          <AiFillDelete
            style={{
              marginRight: "0.5rem",
              color: "#2f14e5",
              cursor: "pointer",
            }}
            onClick={() => deleteFile()}
          />
        )}
        <AiOutlineUpload
          style={{
            marginRight: "0.5rem",
            color: "#2f14e5",
          }}
          onClick={triggerFileInput}
        />
      </div>
      <p style={{ margin: 0 }}>
        {fileName || label}
      </p>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e) => {
          handleFileChange(e);
        }}
      />
    </div>
  );
});

export default FileInputAdvanced;
