import React from 'react'
import { useLocation } from 'react-router-dom'
import { useState, useMemo } from 'react'
import Navbar from '../common/Navbar'
import Sidebar from '../common/Sidebar'
import LoadingProvider from '../../contexts/LoadingContext'
import InputValuesContext from '../../contexts/InputValuesContext'
import AiValidationContext from '../../contexts/AiValidationContext'
import { ToastContainer } from 'react-toastify'
import { useTranslation } from '../../contexts/TranslationProvider'
import "react-toastify/dist/ReactToastify.css";
import { ProjectEntity, ProjectTemplateRequirementEntity } from '../../domain/entities'
import { useProvideContextInputValues } from '../../hooks/useProvideContextInputValues'
import LoadingRessourceProvider from '../../contexts/LoadingRessourceContext'
import MainContent from '../../MainContent'
import AuthUserProvider from '../../contexts/AuthUserContext'
import { ProjectProvider } from '../../contexts/ProjectContext'
import { EditProjectProvider } from '../../contexts/EditProjectContext'

function AppRouter() {
	const location = useLocation();
	const [project, setProject] = useState<ProjectEntity>();
	const [requirement, setRequirement] = useState<ProjectTemplateRequirementEntity>();
	const [requirementType, setRequirementType] = useState<number>();
	const [aiResponsesStatus, setAiResponseStatus] = useState([]);
	const [aiValidationValues, setAiValidationValues] = useState({});
	const [rawSummarySheet, setRawSummarySheet] = useState([]);
	const [aiContractData, setAiContractData] = useState({
		contractName: "",
		contractId: null,
		formId: null,
		textContent: "",
	});
	const [aiCompletionPercentage, setAiCompletionPercentage] = useState(0);

	const aiValidationContextValues = useMemo(
		() => ({
			aiResponsesStatus,
			setAiResponseStatus,
			aiCompletionPercentage,
			setAiCompletionPercentage,
			aiValidationValues, setAiValidationValues,
			aiContractData,
			setAiContractData,
			rawSummarySheet,
			setRawSummarySheet,
			project,
			setProject,
			requirement,
			setRequirement,
			requirementType,
			setRequirementType
		}),
		[aiResponsesStatus, aiCompletionPercentage, rawSummarySheet, aiContractData]
	);
	const isPathExpanded = (pathname) => {
		return pathname !== "/edition-template" && !pathname.includes("/edition-template")
			&& pathname !== "/edition-contrat" && !pathname.includes("/edition-contrat")
			&& pathname !== "/chat" && !pathname.includes("/chat")
			&& !pathname.includes("/document") && !pathname.includes("/edit-form")
			&& !pathname.includes("/ai-edition-contrat")
			&& !pathname.includes("/ai-document-response")
			&& !pathname.includes("/analytics") && !pathname.includes("/use-cases")
			&& !pathname.includes("/edit-project")
	};

	const expanded = isPathExpanded(location.pathname);
	const { language } = useTranslation();
	const dir = language === "ar" ? "rtl" : "ltr";

	return (
		<LoadingProvider>
			<ProjectProvider>
				<EditProjectProvider>
					<AuthUserProvider>
						<LoadingRessourceProvider>
							<InputValuesContext.Provider value={useProvideContextInputValues()}>
								<AiValidationContext.Provider value={aiValidationContextValues}>
									<div dir={dir} className="app-container">
										<Navbar expanded={expanded} />
										<div style={{ display: "flex" }}>
											<Sidebar expanded={expanded} />
											<div className="main-content">
												<MainContent />
											</div>
										</div>
									</div>
								</AiValidationContext.Provider>
							</InputValuesContext.Provider>
							<ToastContainer hideProgressBar={true} autoClose={3000} />
						</LoadingRessourceProvider>
					</AuthUserProvider>
				</EditProjectProvider>
			</ProjectProvider>
		</LoadingProvider>
	)
}

export default AppRouter