import { useNavigate } from "react-router-dom";
import { Ressource } from "../../components/common/RessourceCard";
import RessourceCard from "../../components/common/newDesign/RessourceCard";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { ContractClient } from "../../services/api/ContractClient";
import { FormClient } from "../../services/api/FormClient";
import { ContractAIClient } from "../../services/api/ContractAIClient";
import { DocumentClient } from "../../services/api/DocumentClient";

function RessourcesList({ ressources,  reloadRessources }:
  {
    ressources: Ressource[];
    reloadRessources: () => Promise<void>;
  }
) {
  const navigate = useNavigate();

  const handleClick = (ressource: Ressource) => {
    switch (ressource.type) {
      case 'Form':
        return navigate(`/edit-form/${ressource.id}`)
      case 'Contract':
        return navigate(`/edition-contrat/${ressource.id}`);
      case 'ContractAI':
        return navigate(`/ai-edition-contrat/${ressource.id}`);
      case 'Document':
      return navigate(`/document/${ressource.id}`)
      case 'Summary':
      return  navigate(`/document-summary/${ressource.id}`)
      case 'ContractTemplate':
        return navigate(`/edition-template/${ressource.id}`)
    }
  };
  const apiClient = useApiClientWithLoading()
  const handleDelete = async (ressource: Ressource) => {
    const contractClient = new ContractClient(apiClient)
    const contractAIClient = new ContractAIClient(apiClient)
    const documentClient = new DocumentClient(apiClient)
    const formClient = new FormClient(apiClient)
    let deleted = false;
    switch (ressource.type) {
      case 'Contract':
        ({ deleted } = await contractClient.delete(ressource.id));
        break;
      case 'Form':
        ({ deleted } = await formClient.delete(ressource.id));
        break;
      case 'ContractAI':
        ({ deleted } = await contractAIClient.delete(ressource.id));
        break;
      case 'Document':
      case 'Summary':
        ({ deleted } = await documentClient.delete(ressource.id));
        break;
    }

    if (deleted) {
      await reloadRessources();
    }
  };

  return (
    <section id="contracts-list" className="contracts-list">
      <div className="ressources-list-container">
        {
          ressources.map((ressource) => (
            <RessourceCard
              key={ressource.id + "-" + ressource.type}
              data={ressource}
              onDelete={() => handleDelete(ressource)}
              onClick={() => handleClick(ressource)}
            />
          ))
        }
      </div>
    </section>
  );
}

export default RessourcesList;
