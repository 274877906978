import {useEffect, useRef, useState } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import PendingAi from "../../../components/common/newDesign/PendingAi";
import "../../../components/modals/commonmodal.scss"
function RessourceDocument({ onClose , loading, setLoading, setRessources,ressource}) {
  const [required, setChecked] = useState(ressource ? !ressource.isOptional : true);
  const handleCheckboxChange = () => {
    setChecked(!required);
  };
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const { t, language } = useTranslation();
  const translationPath=t("pages.admin.actionsOptionProjectTemplate")
  const modalContentRef = useRef(null);
  const [req, setReq] = useState({
    name: ressource.name ? ressource.name :""
  });
  const handleSave = async (e) => {
    try {
      e.preventDefault();
      if (!isFormValid) {
        setError("veuillez remplir tout les champs nécessaire");
      }else{
       
        const dataRequirement = {
          ...req,
          isOptional: !required,
          types: [1],
        };
        if (ressource.name) {
          setRessources((prev) => prev.map((r) => (r.name === ressource.name? dataRequirement : r)));
        } else {
          setRessources((prev) => {
            if (Array.isArray(prev)) {
              return [...prev, dataRequirement];
            } else {
              return [dataRequirement]; 
            }
          });
          
        }
        onClose();
      }
    } catch (err) {
      console.log("err :>> ", err);
    }
  };
  const validateForm = () => {
    const isValid =
      req.name !== ""
    setIsFormValid(isValid);
  };
  useEffect(() => {
    validateForm();
  }, [req]);
  return (
    <div>
      {loading ? (
          <PendingAi height="100%" />
      ) : (
        <div ref={modalContentRef}>
          <div style={{ minHeight: "200px" }}>
          <p className="fw-bolder">
            {t("pages.listener.popups.contract.required")}
          </p>
            <div className="form-input-content">
            <div className="form-input-first-content" style={{width:'100%'}}>
              <label htmlFor="contractName" className="case-form-labelName">
              {translationPath.document.name}
              </label>
              <div className="form-floating mb-3 case-form-labelInput">
                <input
                style={{width:"80%", marginTop:"20px", borderRadius:"8px"}}
                  type="text"
                  value={req?.name}
                  className="custom-color-input"
                  id="documentName"
                  onChange={(e) => {
                    e.preventDefault()
                    setReq({ ...req, name: e.target.value });
                    setError("")
                  }}
                />
              </div>
            </div>
            </div>
            <p >
            <label className="case-form-labelName">{translationPath.required}</label>  <input
                type="checkbox"
                checked={required}
                id="checkbox-required"
               value={required.toString()}
                onChange={handleCheckboxChange}
              />
            </p>
            {!isFormValid&&<p style={{color:"red" , fontSize:"13px"}}>{error}</p>}
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary btn-display"
                style={{ left: "4.5%" }}
                onClick={handleSave}
              >
             {ressource.name ? "Editer " :translationPath.document.btn}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RessourceDocument;
