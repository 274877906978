import React, { useState } from "react";
import "./customCombobox.scss";
import { BsChevronDown, BsX } from "react-icons/bs";
import { useTranslation } from "../../../contexts/TranslationProvider";

interface ParamTypeComboboxProps {
  options: any;
  value: any;
  onChange: any;
  disabled?: any;
}

const ParamTypeCombobox = ({
  options,
  value,
  onChange,
  disabled
 }: ParamTypeComboboxProps) => {

  const [isOpen, setIsOpen] = useState(false);
  const { language } = useTranslation();

  const handleOptionClick = (option) => {
    const value = option;
    setIsOpen(false);
    onChange(value);
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation(); // Add this line to stop event propagation
    setIsOpen(!isOpen);
    if (!isOpen) {
    }
  };

  const handleClearClick = (event) => {
    event.stopPropagation(); // Add this line to stop event propagation
    onChange(null);
  };

  const currentOptionDisplay = value;

  return (
      <div className="param-type-container">
        <div className="custom-combobox">
          <div className="param-type-selected" onClick={handleDropdownClick}>
            {currentOptionDisplay}
            <span className={`param-type-actions ${language == "ar" && "arabic-display"}`}>
            {!isOpen && value && !disabled && (
              <BsX
                color="#ff0000" // Set the color for the X icon
                onClick={handleClearClick}
              />
            )}
            <BsChevronDown color={!disabled ? "#2f14e5" : "gray"} />
            </span>
          </div>
          {isOpen && !disabled && (
            <div className="param-type-options">
              {options.map((option, index) => {
                const key = `_${index}`;
                const enabled = option;
                const display = option;
                return (
                  <div key={key} className={"param-type-option"} onClick={() => enabled && handleOptionClick(option)}>
                    {display}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
  );
};

export default ParamTypeCombobox;
