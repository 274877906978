/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useMemo } from 'react'
import useApiClientWithLoading from "../../services/api/ApiClient";
import { useParams } from 'react-router-dom';
import { FormTemplateClient } from '../../services/api/FormTemplateClient';
import { useTranslation } from '../../contexts/TranslationProvider';
import { FormTemplateEntity, FormTemplatePageEntity, GroupEntity } from '../../domain/entities';
import EditFormTemplateContext from '../../contexts/EditFormTemplateContext';
import Loading from "../../components/common/Loading";
import EditFormTemplate from './components/EditFormTemplate';
import { FormParam } from '../../domain/types/FormParams';
// import { EnumOption} from '../../domain/types/FormParams';
import { EnumOption, ListOption, TableLigne } from '../../domain/types/ClauseParams';
import { GroupClient } from '../../services/api/GroupClient';

function FormTemplatePage() {
    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState<GroupEntity[]>([]);
    const apiClient = useApiClientWithLoading();
    const formTemplateClient = new FormTemplateClient(apiClient);
    const groupClient = new GroupClient(apiClient);
    const { formTemplateId } = useParams();
    const { setLanguage, language, t } = useTranslation();
    const [formTemplate, setFormTemplate] = useState<FormTemplateEntity | null>(null);
    const [paramValues, setParamValues] = useState<Record<string, any>[] | null>([]);

    const setTemplateGroups = (group: GroupEntity, add: boolean) => {
        let newGroups = [...formTemplate.groups];
        if (add) {
            newGroups = [...formTemplate.groups, {
                ...group,
                Group_FormTemplate: {
                    pageCodes: formTemplate.pages.map(clause => clause.index)
                }
            }];
        } else {
            newGroups = newGroups.filter(g => g.id !== group.id);
        }
        const newFormTemplate = { ...formTemplate };
        newFormTemplate.groups = newGroups;
        setFormTemplate(newFormTemplate);
    }
    const onSelectGroupInFormPage = async (index: string, newGroups: GroupEntity[]) => {
        console.log(newGroups)
        const templateGroups = formTemplate.groups;
        templateGroups.forEach(group => {
            if (newGroups.some(g => g.id === group.id)) {
                if (!group.Group_FormTemplate.pageCodes.includes(index)) {
                    group.Group_FormTemplate.pageCodes.push(index);
                }
            } else {
                group.Group_FormTemplate.pageCodes = group.Group_FormTemplate.pageCodes.filter(code => code !== index);
            }
        })
        const updatedFormTemplate = { ...formTemplate };
        updatedFormTemplate.groups = templateGroups;
        setFormTemplate(updatedFormTemplate);
    };

    const applyOrder = (oldOrder: FormParam[], orderedItems: { name: string, index: number }[]) => {
        let nonOrderedItems = oldOrder.filter(param => !orderedItems.find(item => item.name === param.name));
        let result = orderedItems.map(item => oldOrder.find(param => param.name === item.name)).filter(p => p !== undefined);
        result = [...result, ...nonOrderedItems];
        return result;
    }

    const addPage = async (index: string, name: string) => {
        const updateBeforeAdd = await formTemplateClient.update(formTemplate.id, formTemplate)
        console.log(index, name, formTemplateId)
        const newTemplate = await formTemplateClient.addPage(parseInt(formTemplateId), name, index.toString());
        setFormTemplate(newTemplate);
    }
    const onParamChanged = (formPageId: FormTemplatePageEntity['id'], updatedParam, newValue) => {
        console.log(updatedParam)
        let newFormTemplateValue = { ...formTemplate };
        newFormTemplateValue.pages = [...formTemplate.pages];
        let pageToUpdate = newFormTemplateValue.pages.find(page => page.id === formPageId);
        if (pageToUpdate) {
            const params = pageToUpdate.params;
            const paramToUpdate = params.find(param => param.name === updatedParam.name);
            if (paramToUpdate) {
                const index = params.indexOf(paramToUpdate);
                pageToUpdate.params[index] = {
                    ...updatedParam,
                    name: newValue ? newValue : updatedParam.name
                };
            }
        } else {
            console.error(`Page with id ${formPageId} not found.`);
        }
        setFormTemplate(newFormTemplateValue)
    };

    const onOptionChanged = (
        formPageId: FormTemplatePageEntity['id'],
        updatedParam: any,
        option: EnumOption | ListOption,
        index: number
    ) => {
        let newFormTemplateValue = { ...formTemplate };

        newFormTemplateValue.pages = formTemplate.pages.map(page => {
            if (page.id === formPageId) {
                let newParams = page.params.map(param => {
                    if (param.name === updatedParam.name) {
                        let newArgs = updatedParam.args || [];
                        if (param.type === "enum") {
                            if (!newArgs[index]) {
                                newArgs[index] = {};
                            }
                            newArgs[index].option = option;
                            if (!newArgs[index].text) {
                                newArgs[index].text = '[ ]';
                            }
                        }
                        if (param.type === "list") {
                            newArgs[index] = option;
                        }
                        return { ...param, args: newArgs };
                    }
                    return param;
                });
                return { ...page, params: newParams };
            }
            return page;
        });

        setFormTemplate(newFormTemplateValue);
    };

    const onValueChange = (formPageId: FormTemplatePageEntity['id'], param: FormParam, value: any) => {
        let newFormTemplateValue = { ...formTemplate };
        let pageToUpdate = newFormTemplateValue.pages.find(page => page.id === formPageId);

        if (pageToUpdate) {
            let paramToUpdate = pageToUpdate.params.find(p => p.name === param.name);
            if (paramToUpdate) {
                setParamValues(prev => ({ ...prev, [param.name]: value }));
            }
        }
    }

    const onResetValue = (formPageId: FormTemplatePageEntity['id'], param: FormParam) => {
        let newFormTemplateValue = { ...formTemplate };
        let pageToUpdate = newFormTemplateValue.pages.find(page => page.id === formPageId);

        if (pageToUpdate) {
            let paramToUpdate = pageToUpdate.params.find(p => p.name === param.name);
            if (paramToUpdate) {
                setParamValues(prev => {
                    delete prev[param.name];
                    return prev;
                });
            }
        }
    }

    const onOptionDelete = (
        formPageId: FormTemplatePageEntity['id'],
        updatedParam: any,
        index: number
    ) => {
        let newFormTemplateValue = { ...formTemplate };

        newFormTemplateValue.pages = formTemplate.pages.map(page => {
            if (page.id === formPageId) {
                let newParams = page.params.map(param => {
                    if (param.name === updatedParam.name) {
                        let newArgs = updatedParam.args || [];
                        if (param.type === "enum" || param.type === "list" || param.type === "table") {
                            newArgs = newArgs.filter((_, i) => i !== index);
                        }
                        return { ...param, args: newArgs };
                    }
                    return param;
                });
                return { ...page, params: newParams };
            }
            return page;
        });

        setFormTemplate(newFormTemplateValue);
    };

    const onOptionAdd = (
        formPageId: FormTemplatePageEntity['id'],
        updatedParam: any,
        option: EnumOption | ListOption | TableLigne
    ) => {
        let newFormTemplateValue = { ...formTemplate };

        newFormTemplateValue.pages = formTemplate.pages.map(page => {
            if (page.id === formPageId) {
                let newParams = page.params.map(param => {
                    if (param.name === updatedParam.name) {
                        let newArgs = updatedParam.args || [];
                        if (param.type === "enum") {
                            newArgs.push(option as EnumOption);
                        }
                        if (param.type === "list") {
                            newArgs.push(option as ListOption);
                        }
                        if (param.type === "table") {
                            newArgs.push(option as TableLigne);
                        }
                        return { ...param, args: newArgs };
                    }
                    return param;
                });
                return { ...page, params: newParams };
            }
            return page;
        });

        setFormTemplate(newFormTemplateValue);
    };


    const onAddParam = async (formPageId: FormTemplatePageEntity['id'], param: FormParam) => {
        let newFormTemplateValue = { ...formTemplate };
        let pageToUpdate = newFormTemplateValue.pages.find(page => page.id === formPageId);
        console.log('param passed to fct:>> ', param);
        if(param.type === 'beneficial'){
            param.args = {
                beneficialTypes: ['Person']
            }
        }
        if (pageToUpdate) {
            // add param to the start if it is not nested
            if (!param.condition || param.condition.length === 0) {
                pageToUpdate.params = [param, ...pageToUpdate.params];
            } else {
                pageToUpdate.params = [...pageToUpdate.params, param];
            }
            setFormTemplate(newFormTemplateValue);
            console.log('formTemplate  after adding new param:>> ', formTemplate);
        } else {
            console.error(`Page with id ${formPageId} not found.`);
        }
    }
    const onSetNewOrder = async (formPageId: FormTemplatePageEntity['id'], newOrder: { name: string, index: number }[]) => {
        let newFormTemplateValue = { ...formTemplate };
        let pageToUpdate = newFormTemplateValue.pages.find(page => page.id === formPageId);
        const oldOrder = pageToUpdate?.params || [];
        const newParams = applyOrder(oldOrder, newOrder);
        pageToUpdate.params = newParams;
        setFormTemplate(newFormTemplateValue);
    }

    const onDeleteParam = async (formPageId: FormTemplatePageEntity['id'], param: FormParam) => {
        let newFormTemplateValue = { ...formTemplate };
        let pageToUpdate = newFormTemplateValue.pages.find(page => page.id === formPageId);

        if (pageToUpdate) {
            pageToUpdate.params = pageToUpdate.params.filter(p =>
                p.type !== param.type ||
                p.name !== param.name ||
                p.label !== param.label
            );
            setFormTemplate(newFormTemplateValue);
        } else {
            console.error(`Page with id ${formPageId} not found.`);
        }
    }

    const onPageRename = async (formPageId: FormTemplatePageEntity['id'], newName: string) => {
        let newFormTemplateValue = { ...formTemplate };
        let pageToUpdate = newFormTemplateValue.pages.find(page => page.id === formPageId);

        if (pageToUpdate) {
            pageToUpdate.name = newName;
            setFormTemplate(newFormTemplateValue);
        } else {
            console.error(`Page with id ${formPageId} not found.`);
        }
    }

    const onTemplateRename = async (newName: string) => {
        let newFormTemplateValue = { ...formTemplate };
        newFormTemplateValue.name = newName;
        setFormTemplate(newFormTemplateValue);
    }

    const onPageDelete = async (index: FormTemplatePageEntity['index']) => {
        const updateBeforDelete = await formTemplateClient.update(formTemplate.id, formTemplate)
        let newFormTemplateValue = await formTemplateClient.removePage(formTemplate.id, index)

        setFormTemplate(newFormTemplateValue);
    }


    useEffect(() => {
        (async () => {
            const row = await formTemplateClient.getById(parseInt(formTemplateId));
            const groupData = await groupClient.getAll();
            setGroups(groupData.rows);
            if (!row) return;
            if (row.languages) {
                const { languages } = row;
                if (languages.length !== 0 && (!(languages as string[]).includes(language))) {
                    setLanguage(languages[0]);
                }
            }
            setFormTemplate(row);
        })();
    }, []);

    const EditFormTemplateContextValue = useMemo(() => ({
        formTemplate,
        setFormTemplate,
        addPage,
        onParamChanged,
        onDeleteParam,
        onAddParam,
        onOptionChanged,
        onOptionDelete,
        onOptionAdd,
        onValueChange,
        paramValues,
        setParamValues,
        onResetValue,
        onSetNewOrder,
        onPageRename,
        onTemplateRename,
        onPageDelete,
        onSelectGroupInFormPage,
        groups,
        setTemplateGroups
    }), [formTemplate,
        setFormTemplate,
        addPage,
        onParamChanged,
        onDeleteParam,
        onAddParam,
        onOptionChanged,
        onOptionDelete,
        onOptionAdd,
        onValueChange,
        paramValues,
        setParamValues,
        onResetValue,
        onSetNewOrder,
        onPageRename,
        onTemplateRename,
        onPageDelete,
        onSelectGroupInFormPage,
        groups,
        setTemplateGroups
    ]);


    return (
        <EditFormTemplateContext.Provider value={EditFormTemplateContextValue}>
            <div className="form-container form-page">
                <div className="d-flex flex-column">
                    {loading ? (<Loading height="90vh" />) : (
                        <EditFormTemplate />
                    )}
                </div>
            </div>
        </EditFormTemplateContext.Provider>
    )
}

export default FormTemplatePage