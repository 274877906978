import { useNavigate } from "react-router-dom";
import RessourceCard from "../../components/common/newDesign/RessourceCard";
import React from "react";
import { ContractEntity } from "../../domain/entities";
import apiClientWithLoading from "../../services/api/ApiClient";
import { ContractClient } from "../../services/api/ContractClient";
import { getName } from "../../helpers/Translation";
import { useTranslation } from "../../contexts/TranslationProvider";
import { FormClient } from "../../services/api/FormClient";

function GroupContractsList({ ressources, groups, reloadContracts }) {
  const navigate = useNavigate();
  const { t, language } = useTranslation();
  const apiClient = apiClientWithLoading()
  const contractClient = new ContractClient(apiClient)
  const formClient = new FormClient(apiClient)

  const handleClick = (ressource) => {
    if(ressource.type === "Contract"){
      navigate(`/edition-contrat/${ressource.id}`);
    }else{
      navigate(`/edit-form/${ressource.id}`);
    }
  };

  const handleDelete = async (ressource) => {
    if(ressource.type === "Contract"){
      try{
        const { deleted } = await contractClient.delete(ressource.id)
        if (deleted) {
          await reloadContracts();
        }
      }catch(err){
        console.error("Error deleting contract: ", err);
      }
    }else{
      try{
        const { deleted } = await formClient.delete(ressource.id)
        if (deleted) {
          await reloadContracts();
        }
      }catch(err){
        console.error("Error deleting form: ", err);
      }
    }
  };

  const GroupColumn = (group) => {
    const groupRessources = ressources.filter((contract) => contract.assignedToGroupId === group.id);
    return (
      <div className="contracts-space" key={group.id}>
        <h3 className="m-3 contracts-space-title">{getName(group, language)}</h3>
        <div className="contracts-per-level">
          {groupRessources.map((ressource) => {
            return (
              <div key={ressource.id} className="m-2 ressource-card">
                <RessourceCard data={ressource}
                  onClick={() => handleClick(ressource)}
                  onDelete={() => handleDelete(ressource)}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <section id="contracts-list" className="group-contracts-list">
      <div className="contracts-list-container">
        {groups.map((group) => GroupColumn(group))}
      </div>
    </section>
  );
}

export default GroupContractsList;
