import React, { useContext, useEffect, useState } from "react";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { toast } from "react-toastify";
import { AxiosInstance } from "axios";
import { useTranslation } from "../../../contexts/TranslationProvider";
import Picture from "../../../assets/Picture.svg";
import {
  BeneficialCompanyEntity,
  BeneficialEntity,
  BeneficialMinorEntity,
  BeneficialPersonEntity,
} from "../../../domain/entities";
import "./beneficialForm.scss";
import { BeneficialClient } from "../../../services/api/BeneficialClient";
import {
  validateEmail,
  validateNumber,
} from "../../../utils/validateStringNumber";
import Loading from "../../../components/common/Loading";
import { getCountryByA3 } from "../../../utils/country";
import { optionsField } from "../../../utils/beneficialFields";
import { set } from "react-hook-form";
const lookup = require("../../../assets/lookup.png");

function BeneficialForm({
  onSave,
  currentBenificialData,
  beneficialsMap,
  setBeneficialsMap,
  beneficialTypes,
  setSelectedBeneficial,
}: {
  setSelectedBeneficial?: React.Dispatch<
    React.SetStateAction<BeneficialEntity>
  >;
  onSave: (id: number) => void;
  currentBenificialData: BeneficialEntity;
  beneficialsMap: Record<string, BeneficialEntity>;
  setBeneficialsMap: React.Dispatch<React.SetStateAction<Record<number, any>>>;
  beneficialTypes: BeneficialEntity["type"][];
}) {
  const [file, setFile] = useState(null);
  const [beneficialData, setBeneficialData] = useState<{
    data: BeneficialEntity;
  }>({
    data: currentBenificialData?.type
      ? currentBenificialData
      : { type: beneficialTypes[0] },
  });
  const [beneficials, setBeneficials] = useState<BeneficialEntity[]>([]);
  const [previewSrc, setPreviewSrc] = useState(
    beneficialData?.data?.profilePhoto?.url || String(Picture)
  );
  const [showMore, setShowMore] = useState(false);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownFirstName, setShowDropdownFirstName] = useState(false);
  const [showDropdownCin, setShowDropdownCin] = useState(false);
  const [filteredCompanyData, setFilteredCompanyData] = useState<
    BeneficialCompanyEntity[]
  >([]);
  const [filteredPersonData, setFilteredPersonData] = useState<
    BeneficialPersonEntity[]
  >([]);
  const { t, language } = useTranslation();
  const apiClient = useApiClientWithLoading(setLoading);
  const beneficialClient = new BeneficialClient(apiClient);
  useEffect(() => {
    beneficialClient.getAll().then(({ rows }) => {
      setBeneficials(rows);
    });
  }, []);
  const handleInputChange = (event, field, subField) => {
    let value = event.target.value;
    if (subField) {
      setBeneficialData({
        data: {
          ...beneficialData.data,
          [subField]: {
            ...(beneficialData.data[subField] || {}),
            [field]: value,
          },
        },
      });
    } else {
      setBeneficialData({
        data: { ...beneficialData.data, [field]: value },
      });
    }
    if (field === "companyName") {
      const filteredCompanies = beneficials.filter(
        (item) =>
          item.type == "Company" &&
          item?.companyName?.toLowerCase().includes(value?.toLowerCase())
      );
      setShowDropdown(value.length > 0 && filteredCompanies?.length > 0);
      setFilteredCompanyData(filteredCompanies as BeneficialCompanyEntity[]);
    }
    if (field === "firstName") {
      const filteredPersons = beneficials.filter(
        (item) =>
          item.type == "Person" &&
          item?.firstName?.toLowerCase().includes(value?.toLowerCase())
      );
      setShowDropdownFirstName(value.length > 0 && filteredPersons?.length > 0);
      setFilteredPersonData(filteredPersons as BeneficialPersonEntity[]);
    }
    if (field === "cin") {
      const filteredPersons = beneficials.filter(
        (item) =>
          item.type == "Person" &&
          item?.cin?.includes(value)
      );
      setShowDropdownCin(value.length > 0 && filteredPersons?.length > 0);
      setFilteredPersonData(filteredPersons as BeneficialPersonEntity[]);
    }
    setErrors({});
  };

  const submitBenificial = async (body: BeneficialEntity) => {
    try {
      setLoading(true);
      let id = beneficialData.data.id;
      let row = id
        ? await beneficialClient.update(id, body)
        : (await beneficialClient.upsert(body)).row;
      id = row.id;
      const newBeneficialsMap = {
        ...beneficialsMap,
        [id]: row,
      };
      if (id) {
        if (file) {
          try {
            row = await beneficialClient.uploadFile(id, "profilePhoto", file);
          } catch (error) {
            console.error("Error uploading profile photo:", error);
          }
        }
      }
      setSelectedBeneficial && setSelectedBeneficial(row);
      setBeneficialsMap(newBeneficialsMap);
      setLoading(false);
      toast.success(
        t("pages.editionContract.popups.benificial.messages.success")
      );
      onSave(id);
    } catch (error) {
      setLoading(false);
      toast.error(t("pages.editionContract.popups.benificial.messages.error"));
      console.error(error);
    }
  };

  const validatePersonForm = () => {
    const personBeneficial = beneficialData.data as BeneficialPersonEntity;
    let formErrors = {};
    const requiredFields: (keyof BeneficialPersonEntity)[] = [
      "firstName",
      "lastName",
      "cin",
    ];
    const fields = Object.keys(personBeneficial) as (keyof BeneficialPersonEntity)[];
    for (const field of requiredFields) {
      if (!fields.includes(field)) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
    }
    for (const field of fields) {
      if (requiredFields.includes(field) && !personBeneficial[field]) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
      if (field === "cin" && !validateNumber(beneficialData.data[field])) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.number"
        );
      }
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length) {
      console.warn(Object.keys(formErrors));
      console.warn(beneficialData.data);
    }
    return Object.keys(formErrors).length === 0;
  };

  const validateCompanyForm = () => {
    const personBeneficial = beneficialData.data as BeneficialPersonEntity;
    let formErrors = {};

    const requiredFields: (keyof BeneficialCompanyEntity)[] = [
      "companyName",
      "registrationNumber"
    ];
    const fields = Object.keys(personBeneficial) as (keyof BeneficialCompanyEntity)[];
    for (const field of requiredFields) {
      if (!fields.includes(field)) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
    }
    for (const field of Object.keys(
      personBeneficial
    ) as (keyof BeneficialCompanyEntity)[]) {
      if (requiredFields.includes(field) && !personBeneficial[field]) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length) {
      console.warn(Object.keys(formErrors));
      console.warn(beneficialData.data);
    }
    return Object.keys(formErrors).length === 0;
  };
  const validateMinorForm = () => {
    const personBeneficial = beneficialData.data as BeneficialMinorEntity;
    let formErrors = {};
    const requiredFields: (keyof BeneficialMinorEntity)[] = [
      "minorFirstName",
      "minorLastName",
      "cin",
      "minorOrderNumber"
    ];
    const fields = Object.keys(personBeneficial) as (keyof BeneficialMinorEntity)[]
    for (const field of requiredFields) {
      if (!fields.includes(field)) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
    }
    for (const field of fields) {
      if (requiredFields.includes(field) && !personBeneficial[field]) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
    }
    setErrors(formErrors);
    if (Object.keys(formErrors).length) {
      console.warn(Object.keys(formErrors));
      console.warn(beneficialData.data);
    }
    return Object.keys(formErrors).length === 0;
  }
  const validateForm = () => {
    return beneficialData.data.type == "Person"
      ? validatePersonForm()
      : beneficialData.data.type == "Company"
        ? validateCompanyForm()
        : validateMinorForm();
  };
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      setPreviewSrc(URL.createObjectURL(event.target.files[0]));
    }
  };
  const handleSave = async (event) => {
    if (validateForm()) {
      setBeneficialData((prevState) => {
        const name =
          prevState.data.type == "Person"
            ? prevState.data.firstName + " " + prevState.data.lastName
            : prevState.data.type == "Company" ? prevState.data.companyName
              : prevState.data.minorFirstName + " " + prevState.data.minorLastName
        const updatedData = {
          ...prevState,
          data: {
            ...prevState.data,
            name,
          },
        };
        (async () => {
          await submitBenificial(updatedData.data);
        })();

        return updatedData;
      });
    }
  };
  const TextInputField = ({
    field,
    label,
    subField = "",
    type = "text",
  }: {
    field:
    | keyof BeneficialPersonEntity
    | keyof BeneficialCompanyEntity
    | keyof BeneficialMinorEntity
    | keyof BeneficialEntity["address"];
    label: string;
    subField?: string;
    type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
  }) => {
    const rawValue = subField
      ? beneficialData.data[subField]?.[field]
      : beneficialData.data[field];

    return (
      <div
        className="mb-3 container-input-beneficial"
        key={`${field}-${subField ?? ""}`}
      >
        <label htmlFor={field} className="label-beneficial">
          {label}
        </label>

        {field === "gender"  || field === "minorGender" ? (
          <div className="d-flex justify-content-between w-50 mt-3">
            <div>
              <input
                name={field}
                type="radio"
                value="M"
                checked={rawValue === "M"}
                onChange={(e) => handleInputChange(e, field, subField)}
              />{" "}
              {t(`pages.editionContract.popups.benificial.gender.M`)}
            </div>
            <div>
              <input
                name={field}
                type="radio"
                value="F"
                checked={rawValue === "F"}
                onChange={(e) => handleInputChange(e, field, subField)}
              />{" "}
              {t(`pages.editionContract.popups.benificial.gender.F`)}
            </div>
          </div>
        ) : (
          <>
            {["socialCapitalCurrency", "nationality", "maritalStatus"].includes(
              field
            ) ? (
              <select
                className="form-control select-options-beneficial"
                id={field}
                value={rawValue}
                onChange={(e) => handleInputChange(e, field, subField)}
                required
              >
                {optionsField
                  ?.find((option) => option.hasOwnProperty(field))
                  ?.[field].map((option) => {
                    const counrty = getCountryByA3(option);
                    console.log(counrty, language);
                    return field === "nationality" ? (
                      <option value={option} key={option}>
                        {counrty[`demonym_${language}`] || counrty.demonym_en}
                      </option>
                    ) : (
                      <option key={option} value={option}>
                        {t(
                          `pages.editionContract.popups.benificial.options.${field}Options.${option}`
                        )}
                      </option>
                    );
                  })}
              </select>
            ) : (
              <div
                className={
                  field === "companyName" || field === "firstName" || field === "cin"
                    ? "input-companyName-container"
                    : "input-beneficial-container"
                }
              >
                <input
                  type={field === "email" ? "email" : type}
                  className="form-control custom-placeholder"
                  id={field}
                  value={rawValue ?? ""}
                  onChange={(event) =>
                    handleInputChange(event, field, subField)
                  }
                />

                {(field === "companyName" || field === "firstName" || field === "cin") && (
                  <img
                    src={lookup}
                    className={`lookup-img ${language === "ar" ? "lookup-img-arabic" : ""
                      }`}
                    onClick={() => {
                      setShowDropdown(false);
                      setShowDropdownFirstName(false);
                    }}
                  />
                )}

                {field === "companyName" &&
                  showDropdown &&
                  filteredCompanyData &&
                  filteredCompanyData.length > 0 && (
                    <div className="company-name-dropdown">
                      {filteredCompanyData.map((item, index) => (
                        <div
                          className="listBeneficial"
                          key={index}
                          onClick={() =>
                            handleDropdownSelect(item.companyName, field)
                          }
                        >
                          {item.companyName}
                        </div>
                      ))}
                    </div>
                  )}
                {field === "firstName" &&
                  showDropdownFirstName &&
                  filteredPersonData &&
                  filteredPersonData.length > 0 && (
                    <div className="company-name-dropdown">
                      {filteredPersonData.map((item, index) => (
                        <div
                          className="listBeneficial"
                          key={index}
                          onClick={() =>
                            handleDropdownSelect(item.firstName, field)
                          }
                        >
                          <div className="d-flex justify-content-between p-1">
                            <p>
                              {item?.fullName}
                            </p>
                            <p>
                              {item?.cin}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                {field === "cin" &&
                  showDropdownCin &&
                  filteredPersonData &&
                  filteredPersonData.length > 0 && (
                    <div className="company-name-dropdown">
                      {filteredPersonData.map((item, index) => (
                        <div
                          className="listBeneficial"
                          key={index}
                          onClick={() =>
                            handleDropdownSelect(item.cin, field)
                          }
                        >
                          <p>
                            {item?.cin}
                          </p>
                        </div>

                      ))}
                    </div>
                  )}
              </div>
            )}
          </>
        )}

        {errors[field] && (
          <small className="text-danger py-0 my-0">{errors[field]}</small>
        )}
      </div>
    );
  };
  const handleComboBoxChange = (field) => (value) => {
    setBeneficialData({
      data: {
        ...beneficialData.data,
        [field]: value,
      },
    });
    if (field === "companyName") {
      const filteredCompanies = beneficials.filter(
        (item) =>
          item.type == "Company" &&
          item.companyName.toLowerCase().includes(value.toLowerCase())
      );
      setShowDropdown(value.length > 0 && filteredCompanies.length > 0);
      setFilteredCompanyData(filteredCompanies as BeneficialCompanyEntity[]);
    }
    setPreviewSrc(beneficialData?.data?.profilePhoto?.url || String(Picture));
  };
  const handleDropdownSelect = (selectedValue, field) => {
    const selectedData = field === "companyName" ? filteredCompanyData.find(
      (company) => company.companyName === selectedValue
    ) : field === "firstName" ? filteredPersonData.find(
      (person) => person.firstName === selectedValue
    ) : filteredPersonData.find(
      (person) => person.cin === selectedValue
    )
    setBeneficialData({
      data: {
        ...selectedData,
        [field]: selectedValue,
      },
    });
    setShowDropdown(false);
    setShowDropdownCin(false)
    setShowDropdownFirstName(false)
  };
  useEffect(() => {
    setPreviewSrc(beneficialData?.data?.profilePhoto?.url || String(Picture));
  }, [beneficialData]);
  const handleToggleShowMore = () => {
    setShowMore((prevState) => !prevState);
  };
  const ProfilePictureField = ({ src, alt, onChange, uniqueId }) => {
    const handleImageClick = () => {
      const fileInput = document.getElementById(`fileInput_${uniqueId}`);
      if (fileInput) {
        fileInput.click();
      }
    };

    return (
      <div className="profile-picture-container">
        <label
          htmlFor={`fileInput_${uniqueId}`}
          className="label-beneficial pb-2"
        >
          {t("pages.editionContract.popups.benificial.inputs.profilePhoto")}
        </label>
        <img
          className="beneficial-profile-img-form"
          src={src}
          alt={alt}
          onClick={handleImageClick}
          style={{ cursor: "pointer" }}
        />
        <input
          id={`fileInput_${uniqueId}`}
          type="file"
          className="form-control file-input"
          onChange={onChange}
          style={{ display: "none" }}
        />
      </div>
    );
  };
  const BeneficialBase = () => {
    return (
      <div className="d-flex wrapper-inputs-form w-100">
        {TextInputField({
          field: "firstName",
          label: t(
            "pages.editionContract.popups.benificial.inputs.person.firstname"
          ),
        })}
        {TextInputField({
          field: "lastName",
          label: t(
            "pages.editionContract.popups.benificial.inputs.person.lastname"
          ),
        })}
        {TextInputField({
          field: "gender",
          label: t(
            "pages.editionContract.popups.benificial.inputs.person.gender"
          ),
        })}
        {TextInputField({
          field: "email",
          label: t(
            "pages.editionContract.popups.benificial.inputs.person.email"
          ),
        })}

        {showMore && (
          <>
            {TextInputField({
              field: "jobTitle",
              label: t(
                "pages.editionContract.popups.benificial.inputs.person.jobTitle"
              ),
            })}
            {TextInputField({
              field: "addressLine",
              subField: "address",
              label: t(
                "pages.editionContract.popups.benificial.inputs.person.address"
              ),
            })}
            {TextInputField({
              field: "dateOfBirth",
              label: t(
                "pages.editionContract.popups.benificial.inputs.person.dateOfBirth"
              ),
              type: "date",
            })}
            {TextInputField({
              field: "placeOfBirth",
              label: t(
                "pages.editionContract.popups.benificial.inputs.person.placeOfBirth"
              ),
            })}
            {TextInputField({
              field: "cin",
              label: t(
                "pages.editionContract.popups.benificial.inputs.person.cin"
              ),
            })}
            {TextInputField({
              field: "cinDeliveryDate",
              label: t(
                "pages.editionContract.popups.benificial.inputs.person.cinDeliveryDate"
              ),
              type: "date",
            })}
            {TextInputField({
              field: "cinDeliverPlace",
              label: t(
                "pages.editionContract.popups.benificial.inputs.person.cinDeliverPlace"
              ),
            })}
            {TextInputField({
              field: "nationality",
              label: t(
                "pages.editionContract.popups.benificial.inputs.person.nationality"
              ),
            })}

            {TextInputField({
              field: "maritalStatus",
              label: t(
                "pages.editionContract.popups.benificial.inputs.person.maritalStatus"
              ),
            })}

            {TextInputField({
              field: "passport",
              label: t(
                "pages.editionContract.popups.benificial.inputs.person.passport"
              ),
            })}
          </>
        )}
      </div>
    );
  };
  return (
    <>
      {loading ? (
        <Loading height="50vh" />
      ) : (
        <div className="" style={{ marginTop: "" }}>
          <div className="form-floating mb-3">
            <div className="slider-wrapper">
              {beneficialTypes.includes("Person") && (
                <div
                  className={`slider-option ${(beneficialTypes.length > 1 &&
                    (language == "ar" ? "right" : "left")) ||
                    ""
                    }  ${beneficialData.data.type === "Person"
                      ? "selected-type-beneficial"
                      : ""
                    }`}
                  onClick={() => handleComboBoxChange("type")("Person")}
                >
                  {t("pages.editionContract.popups.benificial.type1")}
                </div>
              )}
              {beneficialTypes.includes("Company") && (
                <div
                  className={`slider-option ${(beneficialTypes.length > 1 &&
                    (language == "ar" ? "left" : "right")) ||
                    ""
                    } ${beneficialData.data.type === "Company"
                      ? "selected-type-beneficial"
                      : ""
                    }`}
                  onClick={() => handleComboBoxChange("type")("Company")}
                >
                  {t("pages.editionContract.popups.benificial.type2")}
                </div>
              )}
              {
                beneficialTypes.includes("Minor") && (
                  <div
                    className={`slider-option ${(beneficialTypes.length > 1 &&
                      (language == "ar" ? "left" : "right")) ||
                      ""
                      } ${beneficialData.data.type === "Minor"
                        ? "selected-type-beneficial"
                        : ""
                      }`}
                    onClick={() => handleComboBoxChange("type")("Minor")}
                  >
                    {t("pages.editionContract.popups.benificial.type3")}
                  </div>
                )
              }
            </div>
          </div>
          <div className="beneficial-content">
            {beneficialData.data.type === "Person" && (
              <div className="d-flex flex-column w-100">
                {BeneficialBase()}
                <button
                  className="show-more"
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore ? "Show Less" : "Show More"}
                </button>
              </div>
            )}

            {beneficialData.data.type == "Company" && (
              <div className="d-flex flex-column w-100">
                <div className="d-flex wrapper-inputs-form w-100">
                  {TextInputField({
                    field: "companyName",
                    label: t(
                      "pages.editionContract.popups.benificial.inputs.company.companyName"
                    ),
                  })}
                  {TextInputField({
                    field: "socialCapital",
                    label: t(
                      "pages.editionContract.popups.benificial.inputs.company.socialCapital"
                    ),
                    type: "number",
                  })}
                  {TextInputField({
                    field: "postalAddress",
                    label: t(
                      "pages.editionContract.popups.benificial.inputs.company.addressPostal"
                    ),
                  })}
                  {TextInputField({
                    field: "legalForm",
                    label: t(
                      "pages.editionContract.popups.benificial.inputs.company.legalForm"
                    ),
                  })}

                  {showMore && (
                    <>
                      {TextInputField({
                        field: "registrationNumber",
                        label: t(
                          "pages.editionContract.popups.benificial.inputs.company.registrationNumber"
                        ),
                      })}
                      {TextInputField({
                        field: "specialization",
                        label: t(
                          "pages.editionContract.popups.benificial.inputs.company.specialization"
                        ),
                      })}
                      {TextInputField({
                        field: "socialCapitalCurrency",
                        label: t(
                          "pages.editionContract.popups.benificial.inputs.company.socialCapitalCurrency"
                        ),
                      })}
                      {ProfilePictureField({
                        uniqueId: "company",
                        src: previewSrc,
                        alt: "Profile",
                        onChange: handleFileChange,
                      })}
                    </>
                  )}
                </div>
                {showMore && (
                  <div className="beneficial-company-details">
                    <h3>
                      {t(
                        "pages.editionContract.popups.benificial.responsableSociete"
                      )}{" "}
                      :
                    </h3>
                    {BeneficialBase()}
                  </div>
                )}
                <button className="show-more" onClick={handleToggleShowMore}>
                  {showMore ? "Show Less" : "Show More"}
                </button>
              </div>
            )}
            {beneficialData.data.type == "Minor" && (
              <div className="d-flex flex-column w-100">
                <div className="d-flex wrapper-inputs-form w-100">
                  {[
                    TextInputField({
                      field: "minorFirstName",
                      label: t(
                        "pages.editionContract.popups.benificial.inputs.minor.minorFirstName"
                      ),
                    }),
                    TextInputField({
                      field: "minorLastName",
                      label: t(
                        "pages.editionContract.popups.benificial.inputs.minor.minorLastName"
                      ),
                    }),
                    TextInputField({
                      field: "minorGender",
                      label: t(
                        "pages.editionContract.popups.benificial.inputs.minor.minorGender"
                      ),
                    }),
                    TextInputField({
                      field: "minorDateOfBirth",
                      label: t(
                        "pages.editionContract.popups.benificial.inputs.minor.minorDateOfBirth"
                      ),
                      type: "date",
                    }),
                    TextInputField({
                      field: "minorPlaceOfBirth",
                      label: t(
                        "pages.editionContract.popups.benificial.inputs.minor.minorPlaceOfBirth"
                      ),
                    }),
                    TextInputField({
                      field: "minorOrderNumber",
                      label: t(
                        "pages.editionContract.popups.benificial.inputs.minor.minorOrderNumber"
                      ),
                    }),
                    TextInputField({
                      field: "minorOrderDate",
                      label: t(
                        "pages.editionContract.popups.benificial.inputs.minor.minorOrderDate"
                      ),
                      type: "date",
                    }),
                    TextInputField({
                      field: "minorOrderCourt",
                      label: t(
                        "pages.editionContract.popups.benificial.inputs.minor.minorOrderCourt"
                      ),
                    }),
                  ]}
                </div>
                {showMore && (
                  <div className="beneficial-company-details">
                    <h3>
                      {t(
                        "pages.editionContract.popups.benificial.tutor"
                      )}{" "}
                      :
                    </h3>
                    {BeneficialBase()}
                  </div>
                )}
                <button className="show-more" onClick={handleToggleShowMore}>
                  {showMore ? "Show Less" : "Show More"}
                </button>
              </div>
            )}
          </div>
          <button className="save-beneficial-form" type="submit" onClick={handleSave}>
            {t("pages.editionContract.popups.benificial.save")}
          </button>
        </div>
      )}
    </>
  );
}

export default BeneficialForm;
