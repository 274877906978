import { useTranslation } from "../../../../contexts/TranslationProvider"
const Leaf = ({ attributes, children, leaf }) => {
    const { language } = useTranslation()
    if (leaf.bold) {
      children = <span   id="slate_editor_is_highlited" style={{backgroundColor: "#fff4e7"}} className='stale test'>{children}</span>
    }

    if (leaf.code) {
      children = <code className='stale'>{children}</code>
    }

    if (leaf.italic) {
      children = <em  className='stale comment'>{children}</em>
      return <span className='stale text-block' {...attributes}>{children}</span>
      
    }

    if (leaf.underline) {
      children = <u className='stale'>{children}</u>
    }

    return <span className='stale text-block' {...attributes}>{children}</span>
  }

export default Leaf