
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ClauseEntity, ContractEntity, SubClauseEntity } from "../../../domain/entities";
import { findParamBoundaries, getRenderSegments } from "../../../helpers/segmentation";
import { RenderSegments, SegmentedTextType } from "../../../domain/types/ClauseParams";
import SelectionContext from "../../../contexts/SelectionContext";
import "./renderTemplateSegmentedText.scss"
import SlateEditor from "./StaleEditor/SlateEditor";
import { useTranslation } from "../../../contexts/TranslationProvider";


interface RenderTemplateSegmentedTextProps {
	inputValues: Record<string, any>;
	clauseId: ClauseEntity['id'];
	segmentation: ClauseEntity['segmentation'];
	subClauseId?: SubClauseEntity['id'];
	fileNames?: ContractEntity['fileNames'];
	beneficialsMap?: ContractEntity['beneficialsMap'];
	segmentsOverrides: Record<string, string>;
	//setSegmentsOverrides: (v: Record<string, string>) => void;
	onSegmentChange: (id: string, text: string) => void
	isSelected: boolean;
	isEditing: boolean;
	formattedRawRef?: React.MutableRefObject<any>;
	setOpenedPopups: (num: any) => void;
	openPopups: Boolean
}
function RenderTemplateSegmentedText({
	clauseId,
	segmentation,
	subClauseId,
	//setSegmentsOverrides,
	segmentsOverrides,
	onSegmentChange,
	isSelected,
	isEditing,
	inputValues,
	setOpenedPopups,
	openPopups
}: RenderTemplateSegmentedTextProps) {

	const { t, language, tFromTranslations } = useTranslation();

	const {
		setSelected,
		selected,
		isClauseEditing,
		setIsClauseEditing,
	} = useContext(SelectionContext);
	const [changedParamBoundaries, setChangedParamBoundaries] = useState<[number, number][]>([])

	useEffect(() => {
		const paramNames = renderSegments.map(seg => (seg as any).paramName).filter(seg => seg)
		if (selected.eventType == 'ParamValueChange' && selected.paramName && paramNames.includes(selected.paramName)) {
			const boundaries = findParamBoundaries(renderSegments, selected.paramName)
			setChangedParamBoundaries(boundaries)
		} else {
			setChangedParamBoundaries([])
		}
	}, [selected]);

	// use usememo for renderSegments
	const renderSegments: RenderSegments = getRenderSegments(segmentation.segmentedText, inputValues,
		{}, {}, segmentation.segmentedParams, t, language)
		.map(seg => {
			return {
				...seg,
				value: seg.value?.replaceAll(/\r\n/g, "\n"),
			}
		})

	const persistentSegments = useMemo(() => {
		const renderSegments = getRenderSegments(segmentation.segmentedText, inputValues,
			{}, {}, segmentation.segmentedParams, t, language)
			.map(seg => {
				return {
					...seg,
					value: seg.value?.replaceAll(/\r\n/g, "\n"),
				}
			})
		const paramNames = renderSegments.map(seg => (seg as any).paramName).filter(seg => seg)
		if (selected.eventType == 'ParamValueChange' && selected.paramName && paramNames.includes(selected.paramName)) {
			const boundaries = findParamBoundaries(renderSegments, selected.paramName)
			return renderSegments.map((seg, idx) => {
				const isHighlighted = boundaries.some((b) => b[0] < idx && idx < b[1])
				return {
					...seg,
					isHighlighted,
				}
			})
		} else {
			return renderSegments
		}
	}, [segmentation.segmentedText, inputValues, segmentation.segmentedParams])


	return (
		<div className="template-render" style={{ marginBottom: '20px', marginTop: "5px" }}>
			{isEditing ? (
				renderSegments.length > 0 && (<>
					<SlateEditor
						clauseId={clauseId}
						subClauseId={subClauseId}
						onSegmentChange={onSegmentChange}
						segments={persistentSegments}
						params={segmentation.segmentedParams}
						setOpenedPopups={setOpenedPopups}
						openPopups={openPopups}
					/>

				</>)) : (<>
					{renderSegments.map((segment, idx) => {
						const isHighlighted = changedParamBoundaries.some((b) => b[0] < idx && idx < b[1])
						const { id, value, type } = segment
						const key = idx;
						const text = segmentsOverrides[id] ?? value

						const breakedHTMLText = text?.replaceAll(/\n/g, "<br/>")
						switch (type) {
							case SegmentedTextType.PARAM_START:
							case SegmentedTextType.PARAM_END:
								return <React.Fragment key={key}>
								</React.Fragment>
							case SegmentedTextType.STATIC:
								const lines = text.split(/\n/g)
								return <React.Fragment key={key}>
									<span className={`${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""}`} dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></span>

								</React.Fragment>
							case SegmentedTextType.COMMENT:
								return <React.Fragment key={key}>

									<>
										<span className={`comment-prefix ${isHighlighted ? "highlighted-text" : ""}`}>☞ &nbsp;</span>
										<span className={`comment ${isHighlighted ? "highlighted-text" : ""}`} dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></span>
									</>

								</React.Fragment>
							case SegmentedTextType.PARAM:
								const paramPath = value.split(".");
								const param = segmentation.segmentedParams.find((param) => param.name == segment.paramName);
								const paramLabel = param?.label;
								return (
									<span id={`param-${segment.paramName}`} key={key} className={`param ${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""}`}>
										{paramLabel ?? value} {(param.type == 'beneficial' || param.type == 'beneficial[]') && paramPath[1] && `(${paramPath[1]})`}
									</span>
								);
							case SegmentedTextType.PARAM_VALUE:
								return <React.Fragment key={key}>
									<span id={`param-${segment.paramName}`} style={{ whiteSpace: 'pre-line' }} className={`param-value ${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""}`}>{text}</span>
								</React.Fragment>
							case SegmentedTextType.PARAM_TABLE_VALUE:
								const [transposed, tableRows] = JSON.parse(value)
								const [headers, ...rows] = tableRows as string[][]
								return transposed ? (
									<table id={`param-${segment.paramName}`} key={key} className={`my-4 ${isHighlighted ? "highlighted-text" : ""}`} style={{ borderCollapse: "collapse", width: "100%" }}>
										<tbody>
											{headers.map((header, idx) => (
												<tr key={idx}>
													<th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>
														{header}
													</th>
													{rows.map((row, rowIndex) => (
														<td key={rowIndex} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>
															{row[idx]}
														</td>
													))}
												</tr>
											))}
										</tbody>
									</table>
								) :
									(
										<table id={`param-${segment.paramName}`} key={key} className={`my-4 ${isHighlighted ? "highlighted-text" : ""}`} style={{ borderCollapse: "collapse", width: "100%" }}>
											<thead>
												<tr>
													{headers.map((header, idx) =>
														<th key={idx} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }} >
															{header}
														</th>)}
												</tr>
											</thead>
											<tbody>
												{rows.map((row, idx) =>
													<tr key={idx} >
														{row.map((cell, idx) =>
															<td key={idx} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }} >
																{cell}
															</td>)}
													</tr>)
												}
											</tbody>
										</table>
									);
							case SegmentedTextType.PARAM_COMMENT_VALUE:
								return <React.Fragment key={key}>
									<span id={`param-${segment.paramName}`} style={{ whiteSpace: 'pre-line' }} className={`comment ${isHighlighted ? "highlighted-text" : ""}`}>{text}</span>
								</React.Fragment>
							default:
								break;
						}
					})}
				</>
			)
			}

		</div>
	);
}

export default RenderTemplateSegmentedText;
