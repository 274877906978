import { ClauseEntity, ContractEntity } from "../domain/entities";
import { SegmentedTextType } from "../domain/types/ClauseParams";
import { getRenderSegments } from "./segmentation";

export function getRenderedParams(segmentation: ClauseEntity['segmentation'], inputs: Record<ClauseEntity['id'], any>,
  fileNames: ContractEntity['fileNames'], beneficialsMap: ContractEntity['beneficialsMap'],) {
  let renderedParams: string[] = []

  const renderSegments = getRenderSegments(segmentation.segmentedText, inputs, fileNames, beneficialsMap, segmentation.segmentedParams, () => "", "en")


  renderSegments.forEach((segment, idx) => {
    const { type } = segment

    switch (type) {
      case SegmentedTextType.COMMENT:
      case SegmentedTextType.STATIC:
        break;
      case SegmentedTextType.PARAM:
      case SegmentedTextType.PARAM_VALUE:
      case SegmentedTextType.PARAM_COMMENT_VALUE:
      case SegmentedTextType.PARAM_TABLE_VALUE:
      case SegmentedTextType.PARAM_START:
      case SegmentedTextType.PARAM_END:
        renderedParams.push(segment.paramName)
        break;
    }
  }
  );

  return renderedParams
}
