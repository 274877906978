import { useContext } from "react";
import React from "react";
import EditFormContext from "../../../contexts/EditFormContext";
import FormPageInput from "./FormPageInput";
import { useTranslation } from "../../../contexts/TranslationProvider";

import "./editFormInputList.scss"

function EditFormInputList({currentPage,setIndexCurrentPage,currentIndexPage}) {
  const {
    template,
  } = useContext(EditFormContext);

  function onSubmit(e) {
    e.preventDefault();
  }
  let index = template?.pages?.length
  const { t } = useTranslation();
  return (
    <>    <div className="variable-input">
      <form onSubmit={onSubmit}>
        
            <React.Fragment key={currentPage.id}>
              {" "}
              <FormPageInput
                page={currentPage}
              />
            </React.Fragment>
         
      </form>
    </div>
      <div className="form-page-footer">
      <button className={currentIndexPage!==0?"previous-btn":"previous-btn-hidden"} onClick={() => currentIndexPage > 0 ? setIndexCurrentPage(currentIndexPage - 1) : ""}>
        {t("pages.editionForm.previous")}
        </button>
      <button className={currentIndexPage!==index-1?"next-btn":"next-btn-hidden"} onClick={() => currentIndexPage < index - 1 ? setIndexCurrentPage(currentIndexPage + 1) : ""}>
      {t("pages.editionForm.next")}
      </button>
    </div>
    </>

  );
}
export default EditFormInputList;
