import { useEffect, useMemo, useRef, useState } from "react";
import { InputValuesContextType } from "../contexts/InputValuesContext";
import { ContractEntity, ContractTemplateEntity, GroupEntity, ProjectEntity, ProjectTemplateRequirementEntity } from "../domain/entities";
import parseTemplateData from "../helpers/ParseTemplateData";


export function useProvideContextInputValues(): InputValuesContextType {

  const [inputValues, setInputValues] = useState({ contractName: "" });
  const [fileNames, setFileNames] = useState<ContractEntity['fileNames']>({});
  const [beneficialsMap, setBeneficialsMap] = useState<ContractEntity['beneficialsMap']>({});
  const [segmentsOverrides, setSegmentsOverrides] = useState({});
  const [commentsOverrides, setCommentsOverrides] = useState({});
  const [additionalClauses, setAdditionalClauses] = useState<ContractEntity['additionalClauses']>([]);
  const [excludedSubClauses, setExcludedSubClauses] = useState([]);
  const [userGroup, setUserGroup] = useState<number[]>([]);
  const [accessUser, setAccessUser] = useState<Record<string, boolean>>({});
  const [groupContract, setGroupContract] = useState<string>();
  const [project, setProject] = useState<ProjectEntity>();
  const [requirement, setRequirement] = useState<ProjectTemplateRequirementEntity>();
  const [requirementType, setRequirementType] = useState<number>();
  const [excludedClauses, setExcludedClauses] = useState([]);
  const [templateId, setTemplateId] = useState(null);
  const [groups, setGroups] = useState<GroupEntity[]>([]);
  const [contractId, setContractId] = useState(null);
  const [contract, setContract] = useState(null);
  const [contractTemplate, setContractTemplate] = useState<ContractTemplateEntity>(null);
  const [completionPercentage, setCompletionPercentage] = useState(0);

  const contractListParentRef = useRef(null)

  const templateData = useMemo(() => parseTemplateData(contractTemplate, additionalClauses), [contractTemplate, additionalClauses])
  const inputValuesContextValue = useMemo(
    () => ({
      contract, setContract,
      contractTemplate, setContractTemplate,
      templateData,
      inputValues, setInputValues,
      fileNames, setFileNames,
      beneficialsMap, setBeneficialsMap,
      segmentsOverrides,
      setSegmentsOverrides,
      commentsOverrides,
      setCommentsOverrides,
      additionalClauses,
      setAdditionalClauses,
      completionPercentage,
      setCompletionPercentage,
      excludedSubClauses,
      setExcludedSubClauses,
      excludedClauses,
      setExcludedClauses,
      templateId,
      setTemplateId,
      groups,
      setGroups,
      contractId,
      setContractId,
      contractListParentRef,
      setUserGroup,
      userGroup,
      setAccessUser,
      accessUser,
      groupContract,
      setGroupContract,
      project,
      setProject,
      requirement,
      requirementType,
      setRequirementType,
      setRequirement
    }),
    [
      contract,
      contractTemplate,
      templateData,
      inputValues,
      fileNames,
      beneficialsMap,
      segmentsOverrides,
      commentsOverrides,
      additionalClauses,
      completionPercentage,
      excludedSubClauses,
      excludedClauses,
      templateId,
      groups,
      contractId,
      contractListParentRef,
      userGroup,
      accessUser,
      groupContract,
      project,
      requirement,
      requirementType
    ]
  );
  return inputValuesContextValue;
}
