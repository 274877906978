import "./admin.scss";
import { getAuthUser } from "../../services/api/UserAuth";
import AdminFunction from "../../components/AdminFunction";
import { useTranslation } from "../../contexts/TranslationProvider";
import CreateContractTemplateModal from "../../components/modals/CreateContractTemplateModal";
import CreateFormTemplateModal from "../../components/modals/CreateFormTemplateModal";
import CreateReglementModal from "../../components/modals/CreateReglementModal";
import { REACT_APP_DEV_MODE } from "../../config";

function AdminPage() {
  const { user } = getAuthUser();
  const { t } = useTranslation();
  const translationPath = t('pages.admin');
  return (
    <div className="page">
      <div className="px-5">
        <section id="greetings">
          <div className="mb-2 mt-2">
            <span className="current-pathname mb-2">{translationPath.greeting.role} </span>
          </div>
          <h2>
            {translationPath.greeting.message} {" "}
            <span><strong>{user?.firstName}, </strong></span>
          </h2>
          <p></p>
        </section>
        <section id="main-function" className="pt-4">
          <div className="row mt-5">
            <div className="col-5 mb-5">
              <AdminFunction title={translationPath.titleContractTemplate} description={translationPath.descContractTemplate} buttonTitle={t(translationPath.btnContractTemplate)}
                render={(onClose) => <CreateContractTemplateModal onClose={onClose} />} />
            </div>
            <div className="col-5 mb-5">
              <AdminFunction
                title={translationPath.titleFormTemplate}
                description={translationPath.descFormTemplate}
                buttonTitle={translationPath.btnFormTemplate}
                render={(onClose) => <CreateFormTemplateModal onClose={onClose} />}
              />
            </div>
            <div className="col-5 mb-5">
              <AdminFunction
                title={translationPath.titleProject}
                description={translationPath.descProject}
                buttonTitle={translationPath.btnProject}
                path="/admin/create-project-template"
              />
            </div>
            <div className="col-5 mb-5">
              <AdminFunction
                title={translationPath.titleTypes}
                description={translationPath.descTypes}
                buttonTitle={translationPath.btnTypes}
                path="/admin/import-type-levels"
              />
            </div>
            <div className="col-5 mb-5">
              <AdminFunction
                title={translationPath.titleRules}
                description={translationPath.descRules}
                buttonTitle={translationPath.btnRules}
                path="/admin/import-rules"
              />
            </div>
            <div className="col-5 mb-5">
              <AdminFunction
                title={translationPath.titleGroups}
                description={translationPath.descGroups}
                buttonTitle={translationPath.btnGroups}
                path="/admin/list-groups"
              />
            </div>
            { REACT_APP_DEV_MODE && <div className="col-5 mb-5">
              <AdminFunction
                title={"Create a Reglement"}
                description={"Create a new reglement, add your files then start asking your questions"}
                buttonTitle={"Create"}
                render={(onClose) => <CreateReglementModal onClose={onClose} />}
              />
            </div>}
            <div className="col-xxl-3 col-0"></div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AdminPage;
