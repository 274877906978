import React, { useState } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import Loading from "../../common/Loading";
import "./InviteModal.scss";
import { UserGrantEntity } from "../../../domain/entities/UserGrant";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { ContractClient } from "../../../services/api/ContractClient";

function InviteModal({ onClose, setShowExportModal }) {
  const { t, language } = useTranslation();
  const apiClient = useApiClientWithLoading();
  const contractClient = new ContractClient(apiClient)
  const location = useLocation();
  const fullUrl = location.pathname + location.search;
  const contractId = fullUrl.split("/").pop();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    access: [] as UserGrantEntity["access"],
    meta: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    access: "",
  });

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "access") {
      const updatedAccess = checked
        ? [...formData.access, value]
        : formData.access.filter((item) => item !== value);
      setFormData({ ...formData, access: updatedAccess });
      if (updatedAccess.length > 0) {
        setErrors({ ...errors, access: "" });
      }
    } else {
      setFormData({ ...formData, [name]: value });
      setErrors({ ...errors, [name]: "" });
    }
  };
  

  const validateForm = () => {
    let formValid = true;
    const errorsCopy = { ...errors };

    if (!formData.email.trim()) {
      errorsCopy.email = t(
        "pages.editionContract.popups.cocontractorInvitation.fields.errorEmail"
      );
      formValid = false;
    }
    if (formData.access.length === 0) {
      errorsCopy.access =
        t("pages.editionContract.popups.cocontractorInvitation.fields.errorAccess");
      formValid = false;
    }

    setErrors(errorsCopy);
    return formValid;
  };


  const handleInvite = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setLoading(true);
      try {
        const response = await contractClient.inviteUserToContract(
          parseInt(contractId),
          formData.email,
          formData.access,
          formData.meta
        );
        toast.success(
          t("pages.editionContract.popups.cocontractorInvitation.toasts.success")
        );
        onClose();
        setShowExportModal(false);
      } catch (error) {
        toast.error(
          t("pages.editionContract.popups.cocontractorInvitation.toasts.error")
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="cocontractor-add-popup" className="cocontractor-add-popup">
        <div className="modal modal-add-user d-flex justify-content-center align-items-center">
          <div>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t(
                    "pages.editionContract.popups.cocontractorInvitation.title"
                  )}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language === "ar" && "50%" }}
                ></button>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body body-add-user">
                  <form
                    onSubmit={handleInvite}
                    className="form-create-account"
                  >
                    <div className="form-group">
                      <label htmlFor="email">
                        {t(
                          "pages.editionContract.popups.cocontractorInvitation.fields.email"
                        )}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <div className="error-message">{errors.email}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="mb-2">
                        {t(
                          "pages.editionContract.popups.cocontractorInvitation.fields.access"
                        )}
                      </label>
                      {["read", "update", "delete"].map(
                        (accessOption: "read" | "update" | "delete") => (
                          <div key={accessOption} className="mt-2">
                            <label style={{ fontSize: "15px" }}>
                              <input
                                type="checkbox"
                                name="access"
                                value={accessOption}
                                id={`access_${accessOption}`}
                                checked={formData.access.includes(accessOption)}
                                onChange={handleChange}
                                style={{
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                }}
                              />

                              {t(
                                `pages.editionContract.popups.cocontractorInvitation.fields.accessOptions.${accessOption}`
                              )}
                            </label>
                          </div>
                        )
                      )}
                      {errors.access && (
                        <div className="error-message">{errors.access}</div>
                      )}
                    </div>
                    <div className="actions-invite-account w-100">
                      <button
                        type="button"
                        className="cancel-button"
                        onClick={() => {
                          onClose();
                          setShowExportModal(true);
                        }}
                      >
                        {t(
                          "pages.editionContract.popups.cocontractorInvitation.fields.reset"
                        )}
                      </button>
                      <button type="submit" className="submit-invitation">
                        {t(
                          "pages.editionContract.popups.cocontractorInvitation.fields.submit"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InviteModal;
